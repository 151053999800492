
// import fetchMetaTags from '@/utils/FETCH_META_TAGS'
export default {
  name: 'GrievancepolicyPage',

  async asyncData({ app, env, $axios }) {
    const response = await $axios.$post(`${env.API_URL_SITE}/seo`, {
      url: '/grievancepolicy',
      project: 'korzinka.uz'
    })

    const currentLocale = app.i18n.locale
    let page
    try {
      const pageResponse = await $axios.$get(
        `${process.env.API_URL_SITE}/page/`,
        {
          params: {
            url: `/grievancepolicy/`
          }
        }
      )

      page = pageResponse.data.item
    } catch (e) {}

    return {
      page,
      seo: response?.data?.items,
      faq: response?.data?.items?.[currentLocale]?.[0]?.QNA || null
    }
  },

  data: () => ({
    page: [],
    seo: null,
    faq: null,
    slug: ''
  }),

  head() {
    const metaTags = [
      { hid: 'og:type', property: 'og:type', content: 'website' },
      {
        hid: 'description',
        name: 'description',
        content: this.seo[this.$i18n.locale][0].description
      },
      {
        hid: 'og-title',
        property: 'og:title',
        content: this.seo[this.$i18n.locale][0].og_title
      },
      {
        hid: 'og-description',
        property: 'og:description',
        content: this.seo[this.$i18n.locale][0].og_description
      },
      {
        hid: 'og-url',
        property: 'og:url',
        content: this.seo[this.$i18n.locale][0].og_url
      },

      {
        hid: 'og-image',
        property: 'og:image',
        content: this.seo[this.$i18n.locale][0].og_img
      },
      {
        property: 'twitter:card',
        content: 'summary_large_image'
      }
    ]
    if (!this.page?.[this.$i18n.locale]?.index) {
      metaTags.push({
        hid: 'robots',
        name: 'robots',
        content: 'noindex, nofollow'
      })
    }
    return {
      title: this.seo[this.$i18n.locale][0].title,

      meta: metaTags,
      link: [
        {
          rel: 'canonical',
          // href: `https://korzinka.uz/${
          //   this.$i18n.locale === 'ru' ? '' : this.$i18n.locale
          // }${this.slug}`
          href: `https://korzinka.uz/${
            this.$i18n.locale === 'ru' ? '' : this.$i18n.locale + '/'
          }grievancepolicy`
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/grievancepolicy`,
          hreflang: 'ru'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/uz/grievancepolicy`,
          hreflang: 'uz'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/en/grievancepolicy`,
          hreflang: 'en'
        }
      ],
      script: [
        {
          hid: 'ldjson-schema',
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: this.faq.map((item) => ({
              '@type': 'Question',
              name: item.question,
              acceptedAnswer: {
                '@type': 'Answer',
                text: item.answer
              }
            }))
          }
        }
      ]
    }
  },

  mounted() {
    // this.fetchPage()
  },

  methods: {
    // async fetchPage() {
    //   try {
    //     const { items } = await fetchMetaTags(`/grievancepolicy`)
    //     this.seo = items[this.$i18n.locale][0]
    //   } catch (e) {
    //     throw e
    //   }
    // }
  }
}
