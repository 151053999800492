
import StoresModal from './StoresModal.vue'

// import VueJsCounter from 'vue-js-counter'
export default {
  name: 'AboutStoresLayout',

  components: {
    StoresModal
  },

  data() {
    return {
      gallery: [],
      totalMagazine: 0,
      labels: ['Магазин', 'Магазина', 'Магазинов'],
      isOpen: false,
      content: '',
      korzinka: {
        title_ru: 'Купить всё, что нужно',
        title_uz: 'Zarur barchasini xarid qilish',
        title_en: 'Buy everything you need'
      },
      mahalla: {
        title_ru: 'Пополнить запас свежих продуктов',
        title_uz: 'Sarxil mahsulotlar zaxirasini to‘ldirish',
        title_en: 'To stock up on fresh food'
      },
      business: {
        title_ru: 'Закупить для бизнеса',
        title_uz: 'Biznes uchun xaridlar',
        title_en: 'Buy for Business'
      },
      korzinkago: {
        title_ru: 'Заказать домой',
        title_uz: 'Uyga buyurtmalar',
        title_en: 'Order Home'
      }
    }
  },

  computed: {
    label() {
      return this.pluralize(this.totalMagazine, this.labels)
    }
  },
  watch: {
    totalMagazine(newVal) {
      this.storesLabel = this.pluralize(newVal, [
        'Магазин',
        'Магазина',
        'Магазинов'
      ])
    }
  },
  mounted() {
    // const markers = this.$el.querySelectorAll('.about-page__stores-marker')

    // this.markerIdChecker(1079)
    // markers[0].classList.add('activable')

    this.$axios
      .$get(
        `${process.env.API_URL_SITE}/shop_search/?q=&category[]=12&category[]=14&category[]=48&category[]=53&no_status[]=OPENING_SOON`
      )
      .then((result) => {
        this.totalMagazine = result.data.items.ru.length
        // this.storesLabel = this.pluralize(this.totalMagazine, ['Магазин', 'Магазина', 'Магазинов']);
      })

    //   this.$axios
    //     .$get(
    //       `${process.env.API_URL_SITE}/shops/list`
    //     )
    //     .then((result) => {
    //       this.totalMagazine = result.data.items.ru.length
    //     })
  },

  methods: {
    handleModal(content) {
      this.isOpen = true
      this.content = content
    },
    closeModal() {
      this.isOpen = false
    },
    pluralize(num, vars) {
      const cases = [2, 0, 1, 1, 1, 2]
      return vars[
        num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]
      ]
    }
    // async markerIdChecker(id, e) {
    //   const markers = this.$el.querySelectorAll('.about-page__stores-marker')
    //   const target = e ? e.target.closest('.about-page__stores-marker') : false
    //   markers.forEach((m) => m.classList.remove('activable'))
    //   if (target) target.classList.add('activable')
    //   const { data } = await this.$axios.$get(
    //     `${process.env.API_URL_SITE}/gallery/${id}`
    //   )
    //   this.gallery = data.items
    // }
  }
}
