import { render, staticRenderFns } from "./BigBanner.vue?vue&type=template&id=51810b04&scoped=true"
import script from "./BigBanner.vue?vue&type=script&lang=js"
export * from "./BigBanner.vue?vue&type=script&lang=js"
import style0 from "./BigBanner.vue?vue&type=style&index=0&id=51810b04&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51810b04",
  null
  
)

export default component.exports