
export default {
  name: 'TheFooter',
  comments: true,
  props: {
    footerData: {
      type: null,
      default: null
    }
  },
  data() {
    return {
      sortedFooterSocials: [],
      sortedFooterMenu: [],
      footerCert: [
        {
          icon: 'assets/img/sgs/new_SGS.svg',
          href: '/news/iso-certification?id=2080'
        },
        {
          icon: 'assets/img/sgs/new_SGS2.svg',
          href: '/news/iso-certification?id=2080'
        }
      ]
    }
  },
  computed: {
    footerCommon() {
      return this.footerData?.footer?.[this.$i18n.locale]
    },
    // footerCert() {
    //   // return this.footerData?.footer_cert?.[this.$i18n.locale]
    // },
    footerMenu() {
      if (this.footerData?.footer_menu?.[this.$i18n.locale])
        this.sortedFooterMenu = this.footerData.footer_menu[this.$i18n.locale]
      if (this.sortedFooterMenu)
        this.sortedFooterMenu.sort((a, b) => +a.sort - +b.sort)
      return this.sortedFooterMenu
    },
    footerSocials() {
      if (this.footerData?.footer_socnetw?.[this.$i18n.locale])
        this.sortedFooterSocials =
          this.footerData.footer_socnetw[this.$i18n.locale]
      if (this.sortedFooterSocials.length)
        this.sortedFooterSocials.sort((a, b) => +a.sort - +b.sort)

      return this.sortedFooterSocials
    },
    footerCopy() {
      return this.footerCommon?.copyright
        ? this.footerCommon.copyright.split('-')[0]
        : null
    },
    currentYear() {
      return new Date().getFullYear()
    },
    footerPhoneHref() {
      return this.footerCommon?.phone
        ? this.footerCommon.phone.replace(/\D/g, '')
        : null
    }
  },
  mounted() {}
}
