import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2d6c5030"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=2d6c5030&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AboutHistory: require('/home/korzinka/www/front/components/About/History.vue').default,AboutStores: require('/home/korzinka/www/front/components/About/Stores.vue').default,AboutTestimonials: require('/home/korzinka/www/front/components/About/Testimonials.vue').default,AboutStaff: require('/home/korzinka/www/front/components/About/Staff.vue').default,AboutAwards: require('/home/korzinka/www/front/components/About/Awards.vue').default,Charity: require('/home/korzinka/www/front/components/Charity.vue').default,News: require('/home/korzinka/www/front/components/News.vue').default,TheAccordion: require('/home/korzinka/www/front/components/TheAccordion.vue').default,SeoBlock: require('/home/korzinka/www/front/components/SeoBlock.vue').default})
