
export default {
  name: 'BestOfferLayout',

  data: () => ({
    products: [],
    types: {
      row: 'image, paragraph, chip'
    },
    loading: false,
    isShow: true
  }),

  mounted() {
    this.fetchDatas()
  },

  methods: {
    async fetchDatas() {
      try {
        const top10ID = 19
        // const categoryID = process.env.API_URL_CATALOG === 'https://devcatalog.korzinka.uz/api' ? 24 : 19;
        const response = await this.$axios.$get(
          `/catalogs/categories/${top10ID}`
        )
        this.products = response.data.products.splice(0, 10)
        if (!response.data.products.length) {
          this.isShow = false
        }
        if (this.products.length) {
          setTimeout(() => {
            this.isShow = true
            this.loading = true
          }, 1000)
        }
      } catch (e) {
        // throw e
        this.isShow = false
      }
    }
  }
}
