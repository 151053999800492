
export default {
  props: {
    newsId: {
      type: String
    }
  },
  data: (vm) => ({
    isValidForm: true,
    formData: {
      name: '',
      phone: '',
      email: '',
      imageTitle: vm.$t('feedback_page.attachment_request1'),
      review: '',
      image: ''
    }
  }),
  computed: {
    messageCounter() {
      return this.formData.review.split('').length
    }
  },
  watch: {
    'formData.name'() {
      this.checkFormValidity()
    },

    'formData.phone'() {
      this.checkFormValidity()
    },

    'formData.review'() {
      this.checkFormValidity()
    },
    'formData.image'() {
      this.checkFormValidity()
    }
  },
  mounted() {
    const formInputs = this.$el.querySelectorAll('.form-input input')

    formInputs.forEach((i) => {
      i.addEventListener('focusin', (e) => {
        const target = e.target
        target.parentNode.classList.add('focused')
      })

      i.addEventListener('blur', (e) => {
        const target = e.target
        if (target.value === '') {
          target.classList.remove('filled')
          target.parentNode.classList.remove('focused')
        } else {
          target.classList.add('filled')
        }
      })
    })
  },
  methods: {
    checkFormValidity() {
      const numbers = this.formData.phone.split('')
      if (
        this.formData.name !== '' &&
        numbers.length >= 19 &&
        this.formData.image
      ) {
        this.isValidForm = false
      } else {
        this.isValidForm = true
      }
    },
    catchError() {
      this.$refs.button.classList.add('disabled')
      setTimeout(() => {
        this.$refs.failed.classList.add('actived')
        setTimeout(() => {
          this.$refs.failed.classList.remove('actived')
          setTimeout(() => {
            this.$refs.button.classList.remove('disabled')
          }, 300)
        }, 5000)
      }, 300)
    },
    uploadFile() {
      this.formData.image = this.$refs.file.files[0]
      this.formData.imageTitle = this.$refs.file.files[0].name
    },
    submitHandler() {
      const data = new FormData()
      data.append('name', this.formData.name)
      data.append(
        'phone',
        String(this.formData.phone)
          .replace(/ /g, '')
          .replace(/[{()}]/g, '')
      )
      data.append('review', this.formData.review)
      data.append('photo', this.formData.image)
      data.append('email', this.formData.email)
      data.append('news_id', this.newsId)

      this.$axios
        .$post(`${process.env.API_URL_SITE}/feedback_add_from_news`, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (response.success) {
            this.$refs.button.classList.add('disabled')
            setTimeout(() => {
              this.$refs.success.classList.add('actived')

              setTimeout(() => {
                this.$refs.success.classList.remove('actived')
                setTimeout(() => {
                  this.formData.name = ''
                  this.formData.phone = ''
                  this.formData.review = ''
                  this.formData.image = ''
                  this.formData.imageTitle = this.$t(
                    'feedback_page.attachment_request1'
                  )
                  this.formData.email = ''
                  this.$refs.file.value = ''
                  const formInputs = this.$el.querySelectorAll('.form-input')
                  formInputs.forEach((i) => i.classList.remove('focused'))

                  this.$refs.button.classList.remove('disabled')
                }, 300)
              }, 5000)
            }, 300)
          } else {
            this.catchError()
          }
        })
        .catch((err) => {
          console.log('e: ', err)
          this.catchError()
        })
    }
  }
}
