
export default {
  name: 'CharityPage',

  async asyncData({ app, env, $axios }) {
    const response = await $axios.$post(`${env.API_URL_SITE}/seo`, {
      url: '/charity',
      project: "korzinka.uz"
    })

    const currentLocale = app.i18n.locale;

    return { seo: response?.data?.items || null, faq: response?.data?.items?.[currentLocale]?.[0]?.QNA || null }
  },

  data: () => ({
    seo: null,
    faq: null,

    charityData: {}
  }),

  head() {
    const scripts = [];
    if(this?.faq.length) {
      scripts.push({
        hid: 'ldjson-schema',
        type: 'application/ld+json',
        json: {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": this.faq.map(item => ({
            "@type": "Question",
            "name": item.question,
            "acceptedAnswer": {
              "@type": "Answer",
              "text": item.answer
            }
          }))
        }
      })
    }
    return {
      title: this.seo[this.$i18n.locale][0].title,
      meta: [
        { hid: 'og:type', property: 'og:type', content: 'website' },
        {
          hid: 'description',
          name: 'description',
          content: this.seo[this.$i18n.locale][0].description
        },
        {
          hid: 'og-title',
          property: 'og:title',
          content: this.seo[this.$i18n.locale][0].og_title
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content: this.seo[this.$i18n.locale][0].og_description
        },
        {
          hid: 'og-url',
          property: 'og:url',
          content: this.seo[this.$i18n.locale][0].og_url
        },

        {
          hid: 'og-image',
          property: 'og:image',
          content: this.seo[this.$i18n.locale][0].og_img
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `https://korzinka.uz/${
            this.$i18n.locale === 'ru' ? '' : this.$i18n.locale + '/'
          }charity`
        },

        {
          rel: 'alternate',
          href: `https://korzinka.uz/charity`,
          hreflang: 'ru'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/uz/charity`,
          hreflang: 'uz'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/en/charity`,
          hreflang: 'en'
        }
      ],
      script: [...scripts]
    }
  },

  mounted() {
    // try {
    //   const { items } = await fetchMetaTags('/charity')
    //   this.seo = items[this.$i18n.locale][0]
    // } catch (error) {
    //   console.log(error)
    // }

    this.fetchDatas()
  },

  methods: {
    async fetchDatas() {
      const response = await this.$axios.$get(
        `${process.env.API_URL_SITE}/charity`
      )

      this.charityData = response.data
    }
  }
}
