
// import fetchMetaTags from '@/utils/FETCH_META_TAGS'

export default {
  name: 'AboutPage',

  async asyncData({ app, env, $axios }) {
    const response = await $axios.$post(`${env.API_URL_SITE}/seo`, {
      url: '/about',
      project: "korzinka.uz"
    })

    const currentLocale = app.i18n.locale;

    return { seo: response?.data?.items || null, faq: response?.data?.items?.[currentLocale]?.[0]?.QNA || null }
  },

  data: (vm) => ({
    seo: null,
    faq: null,
    video: {},

    types: {
      row: 'image'
    }
  }),

  head() {
    const scripts = [];
    if(this?.faq.length) {
      scripts.push({
        hid: 'ldjson-schema',
        type: 'application/ld+json',
        json: {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": this.faq.map(item => ({
            "@type": "Question",
            "name": item.question,
            "acceptedAnswer": {
              "@type": "Answer",
              "text": item.answer
            }
          }))
        }
      })
    }
    return {
      title: this.seo[this.$i18n.locale][0].title,
      meta: [
        { hid: 'og:type', property: 'og:type', content: 'website' },
        {
          hid: 'description',
          name: 'description',
          content: this.seo[this.$i18n.locale][0].description
        },
        {
          hid: 'og-title',
          property: 'og:title',
          content: this.seo[this.$i18n.locale][0].og_title
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content: this.seo[this.$i18n.locale][0].og_description
        },
        {
          hid: 'og-url',
          property: 'og:url',
          content: this.seo[this.$i18n.locale][0].og_url
        },

        {
          hid: 'og-image',
          property: 'og:image',
          content: this.seo[this.$i18n.locale][0].og_img
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image'
        }
      ],

      bodyAttrs: {
        class: 'header-changable'
      },
      link: [
        {
          rel: 'canonical',
          href: `https://korzinka.uz/${
            this.$i18n.locale === 'ru' ? '' : this.$i18n.locale + '/'
          }about`
        },

        { rel: 'alternate', href: `https://korzinka.uz/about`, hreflang: 'ru' },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/uz/about`,
          hreflang: 'uz'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/en/about`,
          hreflang: 'en'
        }
      ],
      script: [...scripts]

    }
  },


  mounted() {

    this.fetchVideo()
  },

  methods: {
    accordionToggler(e) {
      const id = e.target.getAttribute('data-id')

      this.accordionHeights.forEach((h, idx) => {
        if (Number(id) === idx + 1) {
          if (!this.answers[idx].parentNode.classList.contains('active')) {
            this.answers.forEach((i) => {
              i.parentNode.classList.remove('active')
              i.style.height = `0px`
            })

            this.answers[idx].parentNode.classList.add('active')
            this.answers[idx].style.height = `${h}px`
          } else {
            this.answers[idx].parentNode.classList.remove('active')
            this.answers[idx].style.height = `0px`
          }
        }
      })
    },
    async fetchVideo() {
      const response = await this.$axios.$get(
        `${process.env.API_URL_SITE}/about_video`
      )

      this.video = response.data
    }
  }
}
