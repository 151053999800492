
export default {
  name: 'MobiLayout',

  data: () => ({
    checkerDevice: '',

    content: {}
  }),

  mounted() {
    this.customizeForDevice()

    this.fetchDatas()

    if (this.$refs.mobiData) {
      this.parallaxEffect(
        'mobiData',
        this.$refs.mobiData,
        `main-page-lottie-${this.$i18n.locale}.json`
      )
    }
    if (this.$refs.mobimobiData) {
      this.parallaxEffect(
        'mobimobiData',
        this.$refs.mobimobiData,
        `main-page-lottie-mob-${this.$i18n.locale}.json`
      )
    }
  },

  methods: {
    async fetchDatas() {
      const response = await this.$axios.$get(
        `${process.env.API_URL_SITE}/at_hand`
      )

      this.content = response.data
    },

    parallaxEffect(name, ref, data) {
      const lottieFile = window.lottie.loadAnimation({
        name,
        container: ref,
        loop: false,
        autoplay: false,
        path: '/lottiefiles/main-mobile/' + data
      })

      const tl = window.gsap.timeline()

      tl.to({ frame: 0 }, 1, {
        frame: lottieFile.totalFrames - 1,
        immediateRender: false,
        onUpdate() {
          lottieFile.goToAndStop(Math.round(this.progress() * 1400), false)
        }
      })

      const controller = new window.ScrollMagic.Controller()
      const scene = new window.ScrollMagic.Scene({
        triggerElement: this.$refs.mobi,
        duration: '100%'
      })
        .setTween(tl)
        .addTo(controller)

      scene.offset(-200)
    },

    customizeForDevice() {
      const ua = navigator.userAgent
      const checker = {
        iphone: ua.match(/(iPhone|iPod|iPad)/),
        android: ua.match(/Android/)
      }
      if (checker.iphone) {
        this.checkerDevice = `${process.env.APPSTORE_URI}`
      } else if (checker.android) {
        this.checkerDevice = `${process.env.GOOGLEPLAY_URI}`
      } else {
        this.checkerDevice = `https://korzinka.uz/app/${this.$i18n.locale}`
      }
    }
  }
}
