
export default {
  name: 'TheHeader',

  data: () => ({
    lastScroll: 0,
    isShowLangs: false,
    isShowSearchTools: false,
    isMobileDevice: false,
    types: {
      row: 'image, text'
    },
    headerTypes: {
      row: 'image'
    },
    loading: false,

    headerItems: []
  }),

  head() {
    return {
      meta: [
        {
          name: 'facebook-domain-verification',
          content: 'hkqbytkgcu2eagoaswxkrxji0g8xsv'
        }
      ]
    }
  },

  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  mounted() {
    if (window.innerWidth >= 500) {
      this.isMobileDevice = true
    }
    this.fetchData()
  },

  methods: {
    async fetchData() {
      const response = await this.$axios.$get(
        `${process.env.API_URL_SITE}/header`
      )

      this.headerItems = response.data
    },

    setLanguage(language) {
      // console.log(language);
      this.$cookies.set('preferred_language', language, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365,
        secure: true,
        httpOnly: false,
        sameSite: 'lax'
      })
      this.$router.push(this.switchLocalePath(language))
    },

    handleScroll(e) {
      if (process.client) {
        const y = window.scrollY
        if (y > 0 && this.lastScroll <= y) {
          this.lastScroll = y
          if (y > 50) {
            this.$refs.header.classList.add('header_up')
            this.$refs.header.classList.remove('header_fixed')

            this.isShowLangs = false
          }
        } else {
          this.lastScroll = y
          this.$refs.header.classList.add('header_fixed')
          if (y === 0) {
            this.$refs.header.classList.remove('header_up')
            this.$refs.header.classList.remove('header_fixed')
          }
        }

        return true
      }
    },

    showLangsHandler() {
      this.isShowLangs = !this.isShowLangs
    },

    searchHandler() {
      this.isShowSearchTools = true
    },

    closeHandler(e) {
      this.isShowSearchTools = e
    }
  }
}
