
export default {
  name: 'CTMPage',

  async asyncData({ app, env, $axios }) {
    try {
      const bannersResponse = await $axios.$get(
        `${env.API_URL_SITE}/banners/list`
      )
      const response = await $axios.$post(`${env.API_URL_SITE}/seo`, {
        url: '/ctm',
        project: "korzinka.uz"
      })
  
      const currentLocale = app.i18n.locale;
  
      return { banners: bannersResponse?.data?.items || null, seo: response?.data?.items || null, faq: response?.data?.items?.[currentLocale]?.[0]?.QNA || null }
    } catch (e) {}
  },

  data: () => ({
    seo: null,
    faq: null
  }),

  head() {
    return {
      title: this.seo[this.$i18n.locale][0].title,
      meta: [
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'robots', name: 'robots', content: 'noindex,nofollow' },
        {
          hid: 'description',
          name: 'description',
          content: this.seo[this.$i18n.locale][0].description
        },
        {
          hid: 'og-title',
          property: 'og:title',
          content: this.seo[this.$i18n.locale][0].og_title
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content: this.seo[this.$i18n.locale][0].og_description
        },
        {
          hid: 'og-url',
          property: 'og:url',
          content: this.seo[this.$i18n.locale][0].og_url
        },

        {
          property: 'og:image',
          content: this.seo[this.$i18n.locale][0].og_img
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `https://korzinka.uz/${
            this.$i18n.locale === 'ru' ? '' : this.$i18n.locale + ''
          }`
        },

        { rel: 'alternate', href: `https://korzinka.uz`, hreflang: 'ru' },
        { rel: 'alternate', href: `https://korzinka.uz/uz`, hreflang: 'uz' },
        { rel: 'alternate', href: `https://korzinka.uz/en`, hreflang: 'en' }
      ],
      script: [
        {
          hid: 'ldjson-schema',
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": this.faq.map(item => ({
              "@type": "Question",
              "name": item.question,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": item.answer
              }
            }))
          }
        }
      ]
    }
  }
}
