
export default {
  name: 'PrivacyPolicyInfokioskPage',

  layout: 'empty',

  data: () => ({
    content: {}
  }),

  head() {
    return {
      title: `Korzinka`,
      meta: [{ hid: 'robots', name: 'robots', content: 'noindex,nofollow' }]
    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      const { data } = await this.$axios.$get(
        `${process.env.API_URL_SITE}/privacy_policy`
      )

      this.content = data.item
    }
  }
}
