import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2b881337"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=2b881337&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PostBuilder: require('/home/korzinka/www/front/components/Post/Builder.vue').default,TheAccordion: require('/home/korzinka/www/front/components/TheAccordion.vue').default,SeoBlock: require('/home/korzinka/www/front/components/SeoBlock.vue').default})
