
export default {
  name: 'SpecialMultiCategory',

  props: {
    categories: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    bgColor: {
      type: String,
      required: false
    },
    catalogId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      // navigations: []
    }
  },

  mounted() {
    this.initScrollMagic()

    this.tabsObserver()
    if (this.bgColor === '#E4002B') {
      this.$refs.navigations[0].classList.add(
        'catalog-page__tab-link_active-red'
      )
    } else {
      this.$refs.navigations[0].classList.add('catalog-page__tab-link_active')
    }
    // Автоматический скролл к категории из query параметра
    this.scrollToCategoryFromQuery()
  },

  destroyed() {
    window.removeEventListener('scroll', this.navHighlighter)
  },

  methods: {
    tabsObserver() {
      window.addEventListener('scroll', this.navHighlighter)
    },
    scrollHandler(e) {
      const target = e.target
      const attr = target.getAttribute('data-id')

      const el = this.$el.querySelector(`[data-category='${attr}']`)

      window.scrollTo({ top: el.offsetTop, behavior: 'smooth' })
    },
    scrollToCategoryFromQuery() {
      this.$nextTick(() => {
        const categorySlug = this.$route.query.category
        if (categorySlug) {
          const checkExist = setInterval(() => {
            const categoryElement = this.$el.querySelector(
              `[data-query='${categorySlug}']`
            )
            if (categoryElement) {
              window.scrollTo({
                top: categoryElement.offsetTop,
                behavior: 'smooth'
              })
              clearInterval(checkExist)
            }
          }, 100)
        }
      })
    },
    initScrollMagic() {
      let offset = -50

      if (window.innerWidth < 1200) {
        offset = 0
      }

      const controller = new window.ScrollMagic.Controller()
      const scene = new window.ScrollMagic.Scene({
        triggerElement: this.$refs.triggerEl,
        triggerHook: 0,
        duration:
          this.$refs.scrollBox.offsetHeight -
          this.$refs.sticky.offsetHeight -
          45
      })
        .setPin(this.$refs.sticky)
        .addTo(controller)

      scene.offset(offset)
      document.addEventListener('scroll', function () {
        const tabs = document.getElementById('tabs')
        if (window.getComputedStyle(tabs).position === 'fixed') {
          tabs.classList.add('scrolled')
        } else {
          tabs.classList.remove('scrolled')
        }
      })
    },

    navHighlighter() {
      const sections = document.querySelectorAll('.products-columns')
      const links = document.querySelectorAll('.catalog-page__tab-link')
      const scrollY = window.pageYOffset

      const tabs = document.querySelector('.catalog-page__tabs')
      const tabItems = document.querySelectorAll('.catalog-page__tab-link')

      tabItems.forEach((i, index) => {
        if (i.classList.contains('catalog-page__tab-link_active')) {
          // tabs.scrollLeft = 0
          if (Number(index + 1) === 1) {
            tabs.scrollLeft = 0
            // Done
          }
          if (Number(index + 1) === 2) {
            tabs.scrollLeft = 190
            // Done
          }
          if (Number(index + 1) === 3) {
            tabs.scrollLeft = 450
            // Done
          }
          if (Number(index + 1) === 4) {
            tabs.scrollLeft = 700
            // Done
          }
          if (Number(index + 1) === 5) {
            tabs.scrollLeft = 950
            // Done
          }
          if (Number(index + 1) === 6) {
            tabs.scrollLeft = 1200
            // Done
          }
          if (Number(index + 1) === 7) {
            tabs.scrollLeft = 1450
            // Done
          }
          if (Number(index + 1) === 8) {
            tabs.scrollLeft = 1700
          }
          if (Number(index + 1) === 9) {
            tabs.scrollLeft = 1950
          }
          if (Number(index + 1) === 10) {
            tabs.scrollLeft = 2500
          }
        }
      })

      sections.forEach((current, index) => {
        const sectionHeight = current.offsetHeight
        const sectionTop = current.offsetTop - 220
        const sectionQueryParam = current.getAttribute('data-query')
        const sectionId = current.getAttribute('data-category')

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          const curr = document.querySelector(
            `.stickyBox .catalog-page__tab-link[data-id*='${sectionId}']`
          )
          // Формируем новый query параметр
          const newQuery = `?id=${this.catalogId}&category=${sectionQueryParam}`
          // Проверяем, изменился ли query параметр
          if (newQuery !== this.currentQueryParam) {
            history.pushState(
              { query: sectionQueryParam },
              null,
              `${this.$nuxt.$route.path}${newQuery}`
            )
            // Обновляем текущий query параметр
            this.currentQueryParam = newQuery
          }

          links.forEach((i) =>
            i.classList.remove('catalog-page__tab-link_active')
          )
          links.forEach((i) =>
            i.classList.remove('catalog-page__tab-link_active-red')
          )
          if (this.bgColor === '#E4002B') {
            curr.classList.add('catalog-page__tab-link_active-red')
          } else {
            curr.classList.add('catalog-page__tab-link_active')
          }
        }
      })
    }
  },
  watch: {
    '$route.query.category'(newVal) {
      if (newVal) {
        this.scrollToCategoryFromQuery()
      }
    }
  }
}
