
export default {
  name: 'PostMiddle',

  props: {
    post: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  }
}
