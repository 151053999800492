
import MahallaIcon from './components/MahallaIcon.vue'
import KorzinkaIcon from './components/KorzinkaIcon.vue'
import KorzinkaGoIcon from './components/KorzinkaGoIcon.vue'
import BusinessIcon from './components/BusinessIcon.vue'

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: true
    },
    content: {
      type: String,
      required: true
    }
  },
  components: {
    MahallaIcon,
    KorzinkaGoIcon,
    KorzinkaIcon,
    BusinessIcon
  },
  data() {
    return {
      locale: this.$i18n.locale,
      statik: {
        button_ru: 'Найти магазин',
        button_uz: `Do'konni topish`,
        button_en: 'Find a store'
      },
      korzinkago_btn: {
        button_ru: 'Скачать приложение',
        button_uz: `Ilovani yuklab oling`,
        button_en: 'Download app'
      },
      contents: {
        mahalla: {
          logo: 'mahalla',
          title_ru: 'Пополнить запас свежих продуктов',
          title_uz: 'Sarxil mahsulotlar zaxirasini to‘ldirish',
          title_en: 'To stock up on fresh food',
          list: [
            {
              icon: 'near',
              title_ru: 'Рядом с вами',
              description_ru:
                'Самые важные категории товаров супермаркета в шаговой доступности от дома',
              title_uz: 'Yonginangizda',
              description_uz:
                'Supermarketning eng muhim tovarlar toifalari uyingizdan bir qadamda',
              title_en: 'Close to you',
              description_en:
                'The most important categories of supermarket products within walking distance from home'
            },
            {
              icon: 'fruit',
              title_ru: 'Ассортимент',
              description_ru: 'Широкий выбор овощей и фруктов',
              title_uz: 'Keng tanlov',
              description_uz: 'Meva va sabzavotlarning keng tanlovi',
              title_en: 'Assortment',
              description_en: 'A wide range of fruit and vegetables'
            },
            {
              icon: 'meat',
              title_ru: 'Свежесть продуктов',
              description_ru: 'Витрина свежего мяса и птицы',
              title_uz: 'Mahsulotlar barraligi',
              description_uz:
                'Barra go‘sht va parranda mahsulotlari peshoynasi',
              title_en: 'Fresh products',
              description_en: 'Showcase of fresh meat and poultry'
            },
            {
              icon: 'bread',
              title_ru: 'Готовая еда',
              description_ru:
                'Своя выпечка, гриль и витрина готовой еды для питания без забот',
              title_uz: 'Tayyor taomlar',
              description_uz:
                'O‘z pishiriqlarimiz, grill va oson ovqatlanish uchun tayyor taomlar',
              title_en: 'Ready-to-eat food',
              description_en:
                'Own-cooked bakery, grill and ready-to-eat food display for a worry-free eating'
            },
            {
              icon: 'coffee',
              title_ru: 'Кофе-поинт',
              description_ru:
                'Кофейня самообслуживания и большой ассортимент охлаждённых напитков',
              title_uz: 'Qahva burchagi',
              description_uz:
                'O‘z-o‘ziga xizmat qahvaxonasi va salqin ichimliklarning keng tanlovi',
              title_en: 'Coffee Point',
              description_en:
                'Self-service coffee shop and a large range of chilled beverages'
            },
            {
              icon: 'procent',
              title_ru: 'Акции',
              description_ru:
                'Все акции Корзинки и дополнительные акции для гостей Махалли',
              title_uz: 'Aksiyalar',
              description_uz:
                'Korzinkaning barcha aksiyalari va Mahalla mehmonlari uchun qo‘shimcha aksiyalar',
              title_en: 'Promotions',
              description_en:
                'All Korzinka promotions and additional promotions for Mahalla guests'
            }
          ]
        },
        korzinka: {
          logo: 'korzinka',
          title_ru: 'Купить всё, что нужно',
          title_uz: 'Zarur barchasini xarid qilish',
          title_en: 'Buy everything you need',
          list: [
            {
              icon: 'bag',
              title_ru: 'Для всей семьи',
              description_ru: 'Закупиться для всей семьи на неделю и про запас',
              title_uz: 'Butun oila uchun',
              description_uz:
                'Butun oila uchun hafta va zaxira bozorliklarini tayyorlab qo‘yish',
              title_en: 'For the whole family',
              description_en:
                'Shop for the whole family for a week and in stock '
            },
            {
              icon: 'cart',
              title_ru: 'Ассортимент',
              description_ru:
                'Есть всё: мясо, фрукты и овощи, бакалея, молочные продукты',
              title_uz: 'Keng tanlov ',
              description_uz:
                'Bari bor: go‘sht, meva va sabzavotlar, baqqollik mollari, sut ',
              title_en: 'Assortment',
              description_en:
                'There is everything: meat, fruits and vegetables, groceries, dairy products'
            },
            {
              icon: 'home',
              title_ru: 'Товары для дома',
              description_ru:
                'Широкий ассортимент товаров для дома и личной гигиены всех членов семьи',
              title_uz: 'Uy uchun tovarlar ',
              description_uz: `Uy va oilaning barcha a'zolari shaxsiy gigiyenasi uchun tovarlarning keng tanlovi`,
              title_en: 'Household goods ',
              description_en:
                'A wide range of household and personal care products for all family'
            },
            {
              icon: 'carriage',
              title_ru: 'Для самых маленьких',
              description_ru: 'Большой выбор товаров для детей',
              title_uz: 'Eng jajjilar uchun ',
              description_uz: 'Bolalar uchun tovarlarning keng tanlovi',
              title_en: 'For the youngest ',
              description_en: 'Large selection of products for children'
            },
            {
              icon: 'car',
              title_ru: 'Парковка',
              description_ru: 'Удобно приезжать на автомобиле',
              title_uz: 'Avtoturargoh ',
              description_uz: 'Avtomobilda kelish qulay',
              title_en: 'Parking',
              description_en: 'Convenient to come by car'
            }
          ]
        },
        business: {
          logo: 'business',
          title_ru: 'Закупить для бизнеса',
          title_uz: 'Biznes uchun xaridlar',
          title_en: 'Buy for Business',
          list: [
            {
              icon: 'cart',
              title_ru: 'Оптовые цены',
              description_ru:
                'Более 3500 наименований товаров по оптовым ценам',
              title_en: 'Wholesale prices',
              description_en: 'More than 3,500 items at wholesale prices',
              title_uz: 'Ulgurji narxlar',
              description_uz:
                'Ulgurji narxlarda 3500 dan ortiq turdagi tovarlar'
            },
            {
              icon: 'bag',
              title_ru: 'Все что нужно для вас',
              description_ru:
                'Для всех, кому нужны товары в больших и малых объёмах',
              title_en: 'Everything you need',
              description_en:
                'For everyone who needs goods in large and small quantities',
              title_uz: 'Sizga kerak bo‘lgan barchasi',
              description_uz:
                'Katta va kichik miqdordagi tovarlar zarur bo‘lgan hamma uchun'
            },
            {
              icon: 'card',
              title_ru: 'Оплата по перечислению',
              description_ru:
                'Предоставляем все необходимые сопроводительные документы',
              title_en: 'Payment by transfer',
              description_en:
                'We provide all necessary accompanying documents',
              title_uz: 'Pul o‘tkazish orqali to‘lov',
              description_uz:
                'Barcha tegishli hujjatlarni taqdim etamiz'
            },
            // {
            //   icon: 'card',
            //   title_ru: 'Все способы оплат',
            //   description_ru:
            //     'Все способы оплат, в том числе безналичный расчёт',
            //   title_en: 'All payment methods',
            //   description_en: 'All payment methods, including bank transfer',
            //   title_uz: 'Barcha to‘lov usullari',
            //   description_uz:
            //     'Barcha to‘lov usullarida qabul qilamiz, shuningdek, naqdsiz hisob-kitob ham'
            // },
            {
              icon: 'voucher',
              title_ru: 'Всегда на связи',
              description_ru:
                'По всем вопросам можно обращаться по номеру кол-центра: <a href="tel:+998787771414">+998 (78) 777 14 14</a>',
              title_en: 'Always in touch',
              description_en:
                'For all questions, please contact the call center number: <a href="tel:+998787771414">+998 (78) 777 14 14</a>',
              title_uz: 'Doimo aloqadamiz',
              description_uz:
                'Barcha savollar bo‘yicha call-markaziga murojaat qilishingiz mumkin: <a href="tel:+998787771414">+998 (78) 777 14 14</a>'
            }
          ]
        },
        korzinkago: {
          logo: 'korzinkago',
          title_ru: 'Заказать домой',
          title_uz: 'Uyga buyurtmalar',
          title_en: 'Order Home',
          list: [
            {
              icon: 'korzinka',
              title_ru: 'Цены',
              description_ru: 'Доставка продуктов по ценам, как в Корзинке',
              title_en: 'Prices',
              description_en:
                'Delivery of products at the same prices as in Korzinka',
              title_uz: 'Narxlar',
              description_uz:
                'Korzinkadagidek narxlarda mahsulotlarni yetkazish'
            },
            {
              icon: 'cart',
              title_ru: 'Ассортимент',
              description_ru: 'Широкий ассортимент и удобный поиск товаров',
              title_en: 'Assortment',
              description_en: 'Wide assortment and convenient product search',
              title_uz: 'Keng tanlov',
              description_uz:
                'Tanlovning kengligi va tovarlarni qulay izlash imkoniyati'
            },
            {
              icon: 'сicle',
              title_ru: 'Время доставки',
              description_ru:
                'Выбирайте удобное время доставки и следите за заказом в реальном времени',
              title_en: 'Delivery time',
              description_en:
                'Choose a convenient delivery time and follow your order in real time',
              title_uz: 'Yetkazuv vaqti',
              description_uz:
                'Yetkazuv uchun qulay vaqtni tanlang va buyurtmani ayni vaqt tartibida kuzatib boring'
            },
            {
              icon: 'procent',
              title_ru: 'Акции',
              description_ru: 'Специальные акции и розыгрыши',
              title_en: 'Promotions',
              description_en: 'Special promotions and drawings',
              title_uz: 'Aksiyalar',
              description_uz: 'Maxsus aksiya va yutuqli o‘yinlar'
            },
            {
              icon: 'voucher',
              title_ru: 'Бонусы',
              description_ru:
                'Копите и расплачивайтесь бонусами лояльности с карты Korzinka Plus',
              title_en: 'Bonuses',
              description_en:
                'Save and pay with loyalty bonuses from Korzinka Plus card',
              title_uz: 'Bonuslar',
              description_uz:
                'Korzinka Plus kartalari bilan bonuslar yig‘ing va to‘lang'
            }
          ]
        }
      }
    }
  },
  computed: {
    currentContent() {
      return this.contents[this.content]
    },
    currentUrl() {
      switch (this.currentContent.logo) {
        case 'korzinka': {
          const baseUrlWithLocale = this.getBaseUrl(this.locale)
          const url = `${baseUrlWithLocale}/stores?q&category%5B%5D=12`
          return url
        }
        case 'mahalla': {
          const baseUrlWithLocale = this.getBaseUrl(this.locale)
          const url = `${baseUrlWithLocale}/stores?q=&category%5B%5D=53`
          return url
        }
        case 'korzinkago': {
          // const baseUrlWithLocale = this.getBaseUrl(this.locale)
          const url = `https://4391677.redirect.appmetrica.yandex.com/external?service=grocery&appmetrica_tracking_id=100943487301656635`
          return url
        }
        case 'business': {
          const baseUrlWithLocale = this.getBaseUrl(this.locale)
          const url = `${baseUrlWithLocale}/stores?q&category%5B%5D=48`
          return url
        }
        default:
          return null
      }
    },
    iconComponent() {
      switch (this.currentContent.logo) {
        case 'mahalla':
          return MahallaIcon
        case 'korzinka':
          return KorzinkaIcon
        case 'korzinkago':
          return KorzinkaGoIcon
        case 'business':
          return BusinessIcon
        default:
          return null
      }
    }
  },
  methods: {
    getImgPath(logo) {
      try {
        return require(`@/assets/img/stores/markets/${logo}.png`)
      } catch (e) {
        console.error(`Error loading logo: ${logo}`, e)
        return ''
      }
    },
    getBaseUrl(locale) {
      const baseUrl = 'https://korzinka.uz'
      return locale === 'ru' ? baseUrl : `${baseUrl}/${locale}`
    },
    handleClick() {
      if (this.currentUrl) {
        this.closeModal()

        window.location.href = this.currentUrl // Обновляет страницу и переходит по указанному URL
      }
    },
    getLogoPath(logo) {
      try {
        return require(`@/assets/img/stores/${logo}.svg`)
      } catch (e) {
        console.error(`Error loading logo: ${logo}`, e)
        return ''
      }
    },
    getIconPath(icon) {
      try {
        return require(`@/assets/img/logos/${icon}.svg`)
      } catch (e) {
        console.error(`Error loading icon: ${icon}`, e)
        return ''
      }
    },
    closeModal() {
      this.$emit('closeModal', false)
    }
  },
  watch: {
    isOpen(newVal, oldVal) {
      if (newVal) {
        document.getElementsByTagName('html')[0].classList.add('fixed')
        document
          .getElementsByTagName('header')[0]
          .classList.remove('header_fixed')
      } else {
        document.getElementsByTagName('html')[0].classList.remove('fixed')
      }
    }
  },
  beforeDestroy() {
    document.getElementsByTagName('html')[0].classList.remove('fixed')
  }
}
