
export default {
  name: 'SearchTools',

  props: {
    isShowSearchTools: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },

  data: (vm) => ({
    search: '',
    searchInputEmpty: true,

    settings: {
      slidesToShow: 1,
      arrows: false,
      dots: false,
      infinite: false,
      draggable: false,
      variableWidth: true
    },

    isRequest: true,

    isMobile: false,

    links: [],
    contents: [],

    // Array
    products: [],
    top10Products: [],
    specCatalog: [],
    posts: [],
    stores: [],
    mockProducts: []
  }),

  watch: {
    search: {
      handler(val) {
        const letters = val.split('')
        if (letters.length >= 1) {
          this.searchInputEmpty = false
        } else {
          this.searchInputEmpty = true
        }
      }
    },
    isShowSearchTools: {
      handler(val) {
        const body = document.body

        if (val) {
          this.downSearchTool()
          this.$refs.backBlock.classList.add('active')
          body.classList.add('fixed')
        } else {
          this.upSearchTool()
          setTimeout(() => {
            this.$refs.backBlock.classList.remove('active')
            body.classList.remove('fixed')
          }, 300)
        }
      }
    }
  },

  mounted() {
    this.fetchMockProducts()

    const links = document.querySelectorAll('.search-tools__tabs-link')
    const contents = document.querySelectorAll('.search-tools__tabs-content')

    this.links = document.querySelectorAll('.search-tools__tabs-link')
    this.contents = document.querySelectorAll('.search-tools__tabs-content')

    this.isRequest = false

    links.forEach((i) => {
      i.addEventListener('click', function (e) {
        e.preventDefault()
        links.forEach((i) =>
          i.classList.remove('search-tools__tabs-link_active')
        )
        contents.forEach((c) => {
          c.classList.remove('search-tools__tabs-content_active')
        })
        this.classList.add('search-tools__tabs-link_active')
        const attr = this.getAttribute('data-id')

        const id = document.getElementById(`${attr}`)

        id.classList.add('search-tools__tabs-content_active')
      })
    })

    this.promoTextFetcher()

    if (window.innerWidth <= 768) this.isMobile = true
  },

  methods: {
    async fetchMockProducts() {
      try {
        const { data } = await this.$axios.$get('/mobile/catalogs-list')
        const id = data[0].categories[0].get_products
        const response = await this.$axios.$post(
          '/mobile/catalogs/category/products',
          {
            get_products: id
          }
        )
        this.mockProducts = response.data.splice(0, 5)
      } catch (e) {}
    },

    async fetchDatas() {
      try {
        if (this.search === 'Mol goʻshti' || this.search === 'Beef') {
          this.search = 'Говядина'
        }

        if (this.search === 'Banan' || this.search === 'Banana') {
          this.search = 'Бананы'
        }

        if (this.search === 'Pomidor' || this.search === 'Tomatoes') {
          this.search = 'Помидоры'
        }

        if (
          this.search === 'Tovuq goʻshti' ||
          this.search === 'Chickenʻs meat'
        ) {
          this.search = 'Мясо курицы'
        }

        if (
          this.search === 'Kungaboqar yogʻi' ||
          this.search === 'Sunflower oil'
        ) {
          this.search = 'Масло подсолнечное'
        }

        if (this.search === 'Ichimliklar' || this.search === 'Drink') {
          this.search = 'Напиток'
        }

        if (this.search === 'Olma' || this.search === 'Apples') {
          this.search = 'Яблоки'
        }

        if (
          this.search === 'Bolalar ozuqasi' ||
          this.search === 'Baby formulas'
        ) {
          this.search = 'Смеси детские'
        }

        if (this.search === 'Tuxum' || this.search === 'Eggs') {
          this.search = 'Яйца'
        }

        if (this.search === 'Qoʻy goʻshti' || this.search === 'Lamb') {
          this.search = 'Баранина'
        }

        const config = {
          lang: this.$i18n.locale,
          search: String(this.search) || 's'
        }
        const { data } = await this.$axios.$post(
          `${process.env.API_URL_CATALOG}/mobile/catalogs/search`,
          config
        )

        this.products = data?.product || []
        this.top10Products = data?.top || []
        this.specCatalog = data?.specCatalog || []
      } catch (e) {
        // throw e
        console.error(e)
      }

      try {
        const { data } = await this.$axios.$get(
          `${process.env.API_URL_SITE}/search/?q=${String(this.search) || 's'}`
        )

        this.posts = data.news
        this.stores = data.shops
      } catch (e) {
        console.error(e)
        // throw e
      }

      this.links.forEach((i) =>
        i.classList.remove('search-tools__tabs-link_active')
      )
      this.contents.forEach((c) =>
        c.classList.remove('search-tools__tabs-content_active')
      )

      this.links[0].classList.add('search-tools__tabs-link_active')
      this.contents[1].classList.add('search-tools__tabs-content_active')

      this.isRequest = true
    },

    promoTextFetcher() {
      const body = this.$el.querySelector('.search-tools__promo-inner')

      body.addEventListener('click', (e) => {
        e.preventDefault()

        if (e.target.nodeName !== 'A') return

        this.search = String(e.target.innerText)

        this.fetchDatas()
      })
    },

    downSearchTool() {
      window.gsap.to(this.$refs.searchTools, 0.5, { css: { marginTop: '0%' } })
    },

    upSearchTool() {
      window.gsap.to(this.$refs.searchTools, 0.5, {
        css: { marginTop: '-100%' }
      })
    },

    closeHandler() {
      this.isRequest = false

      this.search = ''

      this.contents.forEach((c) =>
        c.classList.remove('search-tools__tabs-content_active')
      )
      this.contents[0].classList.add('search-tools__tabs-content_active')

      this.$emit('close-handler', false)
    }
  }
}
