
// import AccordionItem from '@/components/AccordionItem.vue';
import AccordionItem from '@/components/TheAccordionItem.vue';

export default {
  components: { AccordionItem },
  props: {
    list: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      activeAccordion: 0,
    }
  },
  methods: {
    toggleAccordion(id) {
      this.activeAccordion = this.activeAccordion === id ? null : id;
    }
  }
}
