
export default {
  name: 'FeedbackPage',

  async asyncData({ app, env, $axios }) {
    const response = await $axios.$post(`${env.API_URL_SITE}/seo`, {
      url: '/feedback',
      project: 'korzinka.uz'
    })

    const currentLocale = app.i18n.locale

    return {
      seo: response?.data?.items,
      faq: response?.data?.items?.[currentLocale]?.[0]?.QNA
    }
  },

  data: (vm) => ({
    isThisPageFeedback: false,
    isValidForm: true,

    seo: null,
    faq: null,

    h: 0,

    classes: [
      'form-select',
      'form-list',
      'form-item',
      'form-link',
      'form-icon',
      'input-tag'
    ],

    isTypes: false,
    isProjects: false,
    isMarkets: false,
    isSwiperTypeModal: false,
    isSwiperProjectModal: false,
    isSwiperMarketModal: false,

    types: {
      options: [],
      id: 0,
      selected: ''
    },

    projects: {
      options: [],
      id: 0,
      selected: ''
    },

    markets: {
      options: [],
      id: 0,
      selected: ''
    },

    formData: {
      name: '',
      phone: '',
      email: '',
      imageTitle: vm.$t('feedback_page.attachment_request'),
      review: '',
      image: ''
    },

    collapsed: false
  }),

  head() {
    const scripts = []
    if (this?.faq.length) {
      scripts.push({
        hid: 'ldjson-schema',
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: this.faq.map((item) => ({
            '@type': 'Question',
            name: item.question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: item.answer
            }
          }))
        }
      })
    }
    return {
      title: this.seo[this.$i18n.locale][0].title,
      meta: [
        { hid: 'og:type', property: 'og:type', content: 'website' },
        {
          hid: 'description',
          name: 'description',
          content: this.seo[this.$i18n.locale][0].description
        },
        {
          hid: 'og-title',
          property: 'og:title',
          content: this.seo[this.$i18n.locale][0].og_title
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content: this.seo[this.$i18n.locale][0].og_description
        },
        {
          hid: 'og-url',
          property: 'og:url',
          content: this.seo[this.$i18n.locale][0].og_url
        },

        {
          hid: 'og-image',
          property: 'og:image',
          content: this.seo[this.$i18n.locale][0].og_img
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image'
        }
      ],

      link: [
        {
          rel: 'canonical',
          href: `https://korzinka.uz/${
            this.$i18n.locale === 'ru' ? '' : this.$i18n.locale + '/'
          }feedback`
        },

        {
          rel: 'alternate',
          href: `https://korzinka.uz/feedback`,
          hreflang: 'ru'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/uz/feedback`,
          hreflang: 'uz'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/en/feedback`,
          hreflang: 'en'
        }
      ],
      script: [...scripts]
    }
  },

  computed: {
    messageCounter() {
      return this.formData.review.split('').length
    }
  },

  watch: {
    'formData.name'() {
      this.checkFormValidity()
    },

    'formData.phone'() {
      this.checkFormValidity()
    },

    'types.id'() {
      this.checkFormValidity()
    },

    'projects.id'() {
      this.checkFormValidity()
    },

    'formData.review'() {
      this.checkFormValidity()
    }
  },

  mounted() {
    this.fetchData()

    // try {
    //   const { items } = await fetchMetaTags('/feedback')
    //   this.seo = items[this.$i18n.locale][0]
    // } catch (error) {
    //   console.log(error)
    // }

    const req = document.querySelector('.req')

    this.isThisPageFeedback = true
    this.initScrollMagic()

    // this.h = this.$refs.content.scrollHeight + 36
    // this.$refs.content.style.height = '0px'

    if (this.$route.query.q === 'true') {
      // this.$refs.requisites.classList.add('active')
      // this.$refs.content.style.height = `${this.h}px`
      this.collapsed = true
    }

    if (this.isThisPageFeedback && req) {
      req.addEventListener('click', (e) => {
        e.preventDefault()

        this.$refs.requisites.classList.add('active')
        this.$refs.content.style.height = `${this.h}px`

        document.getElementById('requisites').scrollIntoView({
          behavior: 'smooth'
        })
      })
    }

    const formInputs = this.$el.querySelectorAll('.form-input input')

    formInputs.forEach((i) => {
      i.addEventListener('focusin', (e) => {
        const target = e.target
        target.parentNode.classList.add('focused')
      })

      i.addEventListener('blur', (e) => {
        const target = e.target
        if (target.value === '') {
          target.classList.remove('filled')
          target.parentNode.classList.remove('focused')
        } else {
          target.classList.add('filled')
        }
      })
    })

    window.addEventListener('click', (e) => {
      this.isTypes = false
      this.isProjects = false
      this.isMarkets = false

      this.selectHandler(e, e.target.getAttribute('data-type'))
    })
  },

  beforeDestroy() {
    window.removeEventListener('click', (e) => {
      this.isTypes = false
      this.isProjects = false

      this.selectHandler(e, e.target.getAttribute('data-type'))
    })

    if (this.$refs.requisites) {
      this.$refs.requisites.classList.add('active')
      this.$refs.content.style.height = `${this.h}px`
    }
  },

  methods: {
    async fetchData() {
      const types = await this.$axios.$get(
        `${process.env.API_URL_SITE}/feedback_message_types`
      )

      const projects = await this.$axios.$get(
        `${process.env.API_URL_SITE}/feedback_projects`
      )

      this.types.options = types.data
      this.projects.options = projects.data
    },
    checkFormValidity() {
      const numbers = this.formData.phone.split('')
      if (
        this.formData.name !== '' &&
        numbers.length >= 19 &&
        this.types.id &&
        this.projects.id &&
        this.formData.review
      ) {
        this.isValidForm = false
      } else {
        this.isValidForm = true
      }
    },

    catchError() {
      this.$refs.button.classList.add('disabled')
      setTimeout(() => {
        this.$refs.failed.classList.add('actived')
        setTimeout(() => {
          this.$refs.failed.classList.remove('actived')
          setTimeout(() => {
            this.$refs.button.classList.remove('disabled')
          }, 300)
        }, 5000)
      }, 300)
    },
    uploadFile() {
      this.formData.image = this.$refs.file.files[0]
      this.formData.imageTitle = this.$refs.file.files[0].name
    },

    submitHandler() {
      const data = new FormData()

      data.append('type', this.types.id)
      data.append('project', this.projects.id)
      data.append('market', this.markets.id)
      data.append('name', this.formData.name)
      data.append(
        'phone',
        String(this.formData.phone)
          .replace(/ /g, '')
          .replace(/[{()}]/g, '')
      )
      data.append('review', this.formData.review)
      data.append('photo', this.formData.image)
      data.append('email', this.formData.email)

      this.$axios
        .$post(`${process.env.API_URL_SITE}/feedback_add`, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (response.success) {
            this.$refs.button.classList.add('disabled')
            setTimeout(() => {
              this.$refs.success.classList.add('actived')

              setTimeout(() => {
                this.$refs.success.classList.remove('actived')
                setTimeout(() => {
                  this.types.id = 0
                  this.projects.id = 0
                  this.markets.id = 0
                  this.types.selected = ''
                  this.projects.selected = ''
                  this.markets.selected = ''
                  this.formData.name = ''
                  this.formData.phone = ''
                  this.formData.review = ''
                  this.formData.image = ''
                  this.formData.imageTitle = this.$t(
                    'feedback_page.attachment_request'
                  )
                  this.formData.email = ''

                  const formInputs = this.$el.querySelectorAll('.form-input')
                  formInputs.forEach((i) => i.classList.remove('focused'))

                  this.$refs.button.classList.remove('disabled')
                }, 300)
              }, 5000)
            }, 300)
          } else {
            this.catchError()
          }
        })
        .catch((err) => {
          console.log('e: ', err)
          this.catchError()
        })
    },

    initScrollMagic() {
      const checkBrowserWidth = window.innerWidth
      let ellipseStroke = 0

      checkBrowserWidth > 500 ? (ellipseStroke = 2756) : (ellipseStroke = 1556)

      const controller = new window.ScrollMagic.Controller()
      new window.ScrollMagic.Scene({
        triggerElement: this.$refs.promo,
        triggerHook: 0,
        duration: this.$refs.promo.offsetHeight
      })
        .setTween(this.$refs.ellipse, 0.1, { width: ellipseStroke })
        .setPin(this.$refs.promo)
        .addTo(controller)
    },

    requisitesToggler() {
      if (!this.$refs.requisites.classList.contains('active')) {
        this.$refs.requisites.classList.add('active')

        this.$refs.content.style.height = `${this.h}px`
      } else {
        this.$refs.requisites.classList.remove('active')
        this.$refs.content.style.height = `0px`
      }
    },

    selectHandler(e, meta) {
      if (window.innerWidth > 992) {
        if (meta === 'type') {
          let strs = ''
          const classNames = e.target.className

          strs = classNames.split(' ')

          if (this.classes.includes(strs[0])) {
            this.isTypes = true
          } else {
            this.isTypes = false
          }

          if (strs[0] === 'form-link') {
            this.isTypes = false
          }
        } else if (meta === 'project') {
          let strs = ''
          const classNames = e.target.className
          strs = classNames.split(' ')

          if (this.classes.includes(strs[0])) {
            this.isProjects = true
          } else {
            this.isProjects = false
          }

          if (strs[0] === 'form-link') {
            this.isProjects = false
          }
        } else {
          let strs = ''
          const classNames = e.target.className
          strs = classNames.split(' ')

          if (this.classes.includes(strs[0])) {
            this.isMarkets = true
          } else {
            this.isMarkets = false
          }

          if (strs[0] === 'form-link') {
            this.isMarkets = false
          }
        }
      } else {
        if (meta === 'type') this.isSwiperTypeModal = true
        if (meta === 'project') this.isSwiperProjectModal = true
        if (meta === 'market') this.isSwiperMarketModal = true
      }
    },

    typeOptionHandler(e, id) {
      this.types.id = id
      this.types.selected = e
    },

    async projectOptionHandler(e, id) {
      this.markets.id = 0
      this.markets.selected = ''
      this.projects.id = id
      this.projects.selected = e

      const markets = await this.$axios.$get(
        `${process.env.API_URL_SITE}/feedback_markets/${id}`
      )

      this.markets.options = markets.data
    },

    marketOptionHandler(e, id) {
      this.markets.id = id
      this.markets.selected = e
    },

    typeSwiperOptionHandler(e, id) {
      this.types.id = id
      this.types.selected = e.target.innerText
      this.isSwiperTypeModal = false
    },

    async projectSwiperOptionHandler(e, id) {
      this.projects.id = id
      this.projects.selected = e.target.innerText
      this.isSwiperProjectModal = false

      const markets = await this.$axios.$get(
        `${process.env.API_URL_SITE}/feedback_markets/${id}`
      )

      this.markets.options = markets.data
    },

    marketSwiperOptionHandler(e, id) {
      this.markets.id = id
      this.markets.selected = e.target.innerText
      this.isSwiperMarketModal = false
    }
  }
}
