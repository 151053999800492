
export default {
  name: 'CouponChecker',
  props: {
    post: {
      type: null,
      default: null
    },
    coupons: {
      type: null,
      default: null
    }
  },
  data() {
    return {
      inputValue: '',
      chancesTitle: '',
      error: false
    }
  },
  computed: {
    formattedValue: {
      get() {
        const chunks = this.inputValue.match(/.{1,5}/g);
        return chunks ? chunks.join(' ') : '';
      },
      set(value) {
        const onlyNumbers = value.replace(/[^\d]/g, '');

        this.inputValue = onlyNumbers;
      }
    },
    coupons_total() {
      return this.coupons?.coupons_amount || 0;
    },
    computedChancesTitle() {
      const lastDigit = this.coupons_total % 10;

      // Определяем последние две цифры числа
      const lastTwoDigits = this.coupons_total % 100;

      // Проверяем на различные варианты склонения
      if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {

        return this.$t('chances_title_3');

      } else if (lastDigit === 1) {

        return this.$t('chances_title');

      } else if (lastDigit >= 2 && lastDigit <= 4) {

        return this.$t('chances_title_2');

      } else {

        return this.$t('chances_title_3');

      }
    },
    couponsList() {
      return this.coupons?.coupons_grouped_by_date || [];
    },
    hasError() {
      return this.error;
    }
  },
  watch: {
    coupons(newVal) {
      if( newVal.coupons_amount === 0 ) this.error = true;
      if( newVal.coupons_amount ) this.error = false;
    },
    inputValue(newVal) {
      if( !newVal ) this.error = false;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.chancesTitle = this.$t('chances_title');
    })
  },
  methods: {
    updateValue(event) {
      const value = event.target.value.replace(/[^\d\s]/g, '');
      event.target.value = value
      this.inputValue = ''
      this.formattedValue = value
    },
    getChances() {
      this.$emit('coupon-checker-input', {
        value: this.inputValue.replaceAll(/\s/g, '') || null,
        apiUrl: this.post?.widget?.link || null
      })
    },
  }
}
