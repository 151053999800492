import { render, staticRenderFns } from "./CouponWrapper.vue?vue&type=template&id=db6eaf0a"
import script from "./CouponWrapper.vue?vue&type=script&lang=js"
export * from "./CouponWrapper.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewsForm: require('/home/korzinka/www/front/components/NewsForm.vue').default,CouponChecker: require('/home/korzinka/www/front/components/CouponChecker.vue').default})
