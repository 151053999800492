
export default {
  name: 'BaseCustomBanner',

  props: {
    banner: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
}
