
export default {
  mode: 'client',
  name: 'NewsSinglePage',
  async asyncData({ app, env, $axios, route, params, error, redirect }) {
    if(route.query.id === '3907'){
      return redirect('https://api.korzinka.uz/Korzinka_annual_report.pdf');
    }

    if (route.query.id) {
      const currentLocale = app.i18n.locale
      try {
        const { data } = await $axios.$get(
          `${process.env.API_URL_SITE}/new/${route.query.id}`
        )

        if (Object.keys(data[currentLocale] || {}).length === 0)
          error({ statusCode: 404, message: 'Post not found' })
        const otherPosts = await $axios.$get(
          `${process.env.API_URL_SITE}/news/list/category-1`
        )
        const ru = otherPosts.data.items.ru.slice(0, 4)
        const uz = otherPosts.data.items.uz.slice(0, 4)
        const en = otherPosts.data.items.en.slice(0, 4)
        const responseOtherPosts = {
          ru: [],
          uz: [],
          en: []
        }
        ru.forEach((i) => {
          if (i.id !== route.query.id) responseOtherPosts.ru.push(i)
        })
        uz.forEach((i) => {
          if (i.id !== route.query.id) responseOtherPosts.uz.push(i)
        })
        en.forEach((i) => {
          if (i.id !== route.query.id) responseOtherPosts.en.push(i)
        })
        responseOtherPosts.ru = responseOtherPosts.ru.slice(0, 3)
        responseOtherPosts.uz = responseOtherPosts.uz.slice(0, 3)
        responseOtherPosts.en = responseOtherPosts.en.slice(0, 3)

        const { slug } = params
        const response = await $axios.$post(`${env.API_URL_SITE}/seo`, {
          url: `/news/${slug}?id=${route.query.id}`,
          project: 'korzinka.uz'
        })
        return {
          post: data || null,
          showWidget: !Array.isArray(data?.[currentLocale]?.widget),
          showForm: data[currentLocale].show_form,
          otherPosts: responseOtherPosts || null,
          seo: response?.data?.items?.[currentLocale]?.[0] || null
        }
      } catch (e) {
        // error({ statusCode: 404, message: 'Post not found' })
      }
    } else {
      error({ statusCode: 404, message: 'Post not found' })
    }
  },
  data() {
    return {
      isSubscribe: false,
      post: null,
      seo: null,
      chances: 'шанс',
      couponsData: null
    }
  },
  head() {
    const baseUrl = 'https://korzinka.uz'
    const slug = this.$route.params.slug
    const id = this.$route.query.id

    let url = ''
    const locale = this.$i18n.locale

    if (locale === 'ru') {
      url = `${baseUrl}/news/${slug}?id=${id}`
    } else {
      url = `${baseUrl}/${locale}/news/${slug}?id=${id}`
    }
    const metaTags = [
      {
        hid: 'description',
        name: 'description',
        content:
          this.post?.[this.$i18n.locale]?.seo?.description ||
          this.seo?.description ||
          this.post?.[this.$i18n.locale].name ||
          'Korzinka | News description'
      },
      {
        hid: 'keywords',
        name: 'keywords',
        content: this.post?.[this.$i18n.locale]?.seo?.keywords || ''
      },
      {
        hid: 'og-title',
        property: 'og:title',
        content:
          this.post?.[this.$i18n.locale]?.seo?.title ||
          this.post?.[this.$i18n.locale]?.name ||
          'Korzinka | News'
      },
      {
        hid: 'og-description',
        property: 'og:description',
        content:
          this.post?.[this.$i18n.locale]?.seo?.description ||
          this.seo?.description ||
          this.post?.[this.$i18n.locale]?.name ||
          'Korzinka | News description'
      },
      {
        hid: 'og-url',
        property: 'og:url',
        content: url
      },
      {
        hid: 'og-image',
        property: 'og:image',
        content:
          this.post?.[this.$i18n.locale]?.seo?.pic_name ||
          this.seo?.og_img ||
          this.post?.[this.$i18n.locale]?.detail_picture ||
          ''
      }
    ]
    if (!this.post?.[this.$i18n.locale]?.index) {
      metaTags.push({
        hid: 'robots',
        name: 'robots',
        content: 'noindex, nofollow'
      })
    }
    if (id === '1979') {
      metaTags.push({
        hid: 'robots',
        name: 'robots',
        content: 'noindex, nofollow'
      })
    }
    return {
      title:
        this.post?.[this.$i18n.locale]?.seo?.title ||
        this.seo?.title ||
        `Korzinka | ${this.post?.[this.$i18n.locale]?.name}` ||
        'Korzinka | News title',
      meta: metaTags,
      link: [
        {
          rel: 'canonical',
          href:
            this.post?.[this.$i18n.locale]?.[
              'canonical_' + this.$i18n.locale
            ] || url
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/news/${this.$route.params.slug}?id=${this.$route.query.id}`,
          hreflang: 'ru'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/uz/news/${this.$route.params.slug}?id=${this.$route.query.id}`,
          hreflang: 'uz'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/en/news/${this.$route.params.slug}?id=${this.$route.query.id}`,
          hreflang: 'en'
        }
      ]
    }
  },
  computed: {
    postIsEmpty() {
      return Object.keys(this?.post[this.$i18n.locale] || {}).length === 0
    },
    isOldCouponChecker() {
      const regex = /id="coupon"|id="coupon_nan"/
      return regex.test(this?.post[this.$i18n.locale]?.detail_text)
    },
    content() {
      if (this.isOldCouponChecker)
        return this.post?.[this.$i18n.locale]?.detail_text
          ? this.post?.[this.$i18n.locale]?.detail_text.replace('[WIDGET]', '')
          : ''

      return this.post?.[this.$i18n.locale]?.detail_text
    }
  },
  mounted() {
    if (this.isOldCouponChecker) {
      const cCh = document.querySelector('.coupon-checker')
      if (cCh) {
        this.handleCouponChecker()
      }
    }
  },
  methods: {
    async getCoupons({ value, apiUrl }) {
      try {
        const { data } = await this.$axios.get(`${apiUrl}${value}`)
        this.couponsData = data
      } catch (e) {
        this.couponsData = { coupons_amount: 0 }
      }
    },
    subscribeHandler() {
      this.isSubscribe = !this.isSubscribe
    },
    checkCountChances(count) {
      if (this.$i18n.locale === 'ru') {
        let formOfWord = 'шанс'
        if (count === 1 || count === 21 || count === 31 || count === 41) {
          this.chances = formOfWord
        } else {
          if (count % 10 === 1 && count % 100 !== 11) {
            formOfWord += 'а'
          } else if (
            count % 10 >= 2 &&
            count % 10 <= 4 &&
            (count % 100 < 10 || count % 100 >= 20)
          ) {
            formOfWord += 'а'
          } else {
            formOfWord += 'ов'
          }
          this.chances = formOfWord
        }
      } else if (this.$i18n.locale === 'uz') {
        this.chances = 'ta imkon bor'
      } else {
        this.chances = 'chances'
      }
    },
    handleCouponChecker() {
      const DATA = {
        title: {
          ru: 'Узнайте свои шансы на выигрыш',
          uz: 'Yutuq uchun imkonlaringizni aniqlang',
          en: 'Find out your chances of winning'
        },
        label: {
          ru: 'Данные об участии в акции обновляются в течение 60 минут после покупки. Для проверки введите номер карты лояльности ниже:',
          uz: 'Aksiyadagi ishtirok haqida ma`lumotlar xariddan 60 daqiqa oʻtib yangilanadi. Tekshirish uchun Korzinka kartangiz raqamini quyida kiriting:',
          en: 'Participation data is updated within 60 minutes of purchase. To verify, enter your loyalty card number below:'
        },
        button: {
          ru: 'Проверить участие',
          uz: 'Ishtirokni tekshiring',
          en: 'Check Participation'
        },
        error: {
          ru: 'Похоже, вы пока не участвуете в акции или данные еще не обновлены. Если с момента покупки прошло больше 60 минут — свяжитесь с нами по телефону <a href="tel:+998781401414">+998 (78) 140-14-14</a>',
          uz: 'Siz hali aksiyada qatnashmayotgan koʻrinasiz yoki ma`lumotlaringiz yangilanmagan. Xarid qilganingizdan keyin 60 daqiqadan ko‘proq o‘tgan bo‘lsa — biz bilan <a href="tel:+998781401414">+998 (78) 140-14-14</a> raqami orqali bog‘laning',
          en: 'Looks like you`re not participating in the promotion yet or the data hasn`t been updated yet. If more than 60 minutes have passed since the purchase, please contact us by phone <a href="tel:+998781401414">+998 (78) 140-14-14</a>'
        },
        firstTitle: {
          ru: 'У вас',
          uz: 'Sizda',
          en: 'You have'
        },
        number: {
          ru: 'Номера участия',
          uz: 'Ishtirok raqamlari',
          en: 'Participation numbers'
        }
      }
      const checkerButton = document.querySelector('.coupon-checker__button')
      const checkerInput = document.querySelector(
        '.coupon-checker__form-group input'
      )
      const schedules = document.querySelector('.schedules')
      const scheduleItems = document.querySelector('.coupon-checker__schedules')
      const image = document.querySelector('.coupon-checker__image')
      const clearEl = document.querySelector('.coupon-checker__clear')
      const errorEl = document.querySelector('.coupon-checker__error')
      const odds = document.querySelector(
        '.coupon-checker__odds .coupon-checker__odds_l'
      )
      // Content
      const cTitle = document.querySelector('.coupon-checker__title')
      const cLabel = document.querySelector('.coupon-checker__form-group label')
      const cButton = document.querySelector('.coupon-checker__button span')
      const cTitleF = document.querySelector('.coupon-checker__odds_f')
      cTitle.textContent = DATA.title[this.$i18n.locale]
      cLabel.textContent = DATA.label[this.$i18n.locale]
      cButton.textContent = DATA.button[this.$i18n.locale]
      cTitleF.textContent = DATA.firstTitle[this.$i18n.locale]
      const couponTemplate = (coupon) => {
        const formatDate = String(coupon.date).split(' ')
        const modifiedDate = String(formatDate[0]).split('-')
        const date = `${modifiedDate[2]}.${modifiedDate[1]}.${modifiedDate[0]}`
        const time = formatDate[1]
        return `
        <div class="coupon-checker__schedule">
          <div class="coupon-checker__schedule-item">
            <div class="coupon-checker__coupons">
            ${Array.from(coupon.coupons)
              .map((i) => {
                return `<span class="coupon-checker__coupon ${
                  i.has_expired ? 'expired' : ''
                }">
                        <span>${i.id}</span>
                      </span>`
              })
              .join('')}
            </div>
            <span class="coupon-checker__number">${
              DATA.number[this.$i18n.locale]
            }</span>
          </div>
          <div class="coupon-checker__schedule-item">
            <span class="coupon-checker__date">${date}</span>
            <span class="coupon-checker__time">${time}</span>
          </div>
        </div>
      `
      }
      clearEl.addEventListener('click', (e) => {
        e.preventDefault()
        checkerInput.value = ''
        clearEl.classList.add('disable')
        checkerButton.classList.add('disable')
        image.classList.remove('disable')
        schedules.classList.remove('active')
        errorEl.textContent = ''
      })
      /* eslint-disable no-undef */
      const im = new Inputmask({
        mask: '99999 99999 99999 99999',
        placeholder: '',
        greedy: true
      })
      im.mask(checkerInput)
      checkerInput.addEventListener('input', (e) => {
        const _Length = String(e.target.value.replaceAll(/\s/g, '')).length
        if (_Length >= 12) {
          checkerButton.classList.remove('disable')
          errorEl.textContent = ''
        } else {
          checkerButton.classList.add('disable')
          errorEl.textContent = ''
        }
        if (_Length > 0) {
          clearEl.classList.remove('disable')
          errorEl.textContent = ''
        } else {
          clearEl.classList.add('disable')
          errorEl.textContent = ''
        }
      })
      checkerButton.addEventListener('click', async (e) => {
        e.preventDefault()
        const isIDNan = !!this.$el.querySelector('#coupon_nan')
        try {
          const { data } = await this.$axios.get(
            !isIDNan
              ? `/promotions/coupons/${checkerInput.value.replaceAll(
                  /\s/g,
                  ''
                )}`
              : `/temporary/promotion/coupons/${checkerInput.value.replaceAll(
                  /\s/g,
                  ''
                )}`
          )
          errorEl.textContent = ''
          if (data.coupons_amount > 0) {
            this.checkCountChances(data.coupons_amount)
            scheduleItems.innerHTML = ''
            image.classList.add('disable')
            schedules.classList.add('active')
            odds.textContent = `${data.coupons_amount} ${this.chances}`
            data.coupons_grouped_by_date.forEach((i) => {
              scheduleItems.insertAdjacentHTML('beforeend', couponTemplate(i))
            })
          } else {
            errorEl.innerHTML = DATA.error[this.$i18n.locale]
            image.classList.remove('disable')
            schedules.classList.remove('active')
          }
        } catch (e) {
          errorEl.textContent = e.response?.data.message
        }
      })
    }
  }
}
