
import animeData from '~/static/lottiefiles/circle.json'
import cardsData from '~/static/lottiefiles/cards.json'

export default {
  name: 'LoyaltyInfokioskPage',

  layout: 'empty',

  data: (vm) => ({
    accordionHeights: [],
    answers: [],

    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      infinite: false,
      variableWidth: true,
      speed: 250,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    },

    prevIconLottiefile: null,
    nextIconLottiefile: null,

    checkerDevice: '',

    QNA: [],
    getCards: {},
    cardBlock: {},
    speedPoints: {},
    cashback: {},

    isDevice: ''
  }),

  head() {
    return {
      title: `Korzinka`,
      meta: [{ hid: 'robots', name: 'robots', content: 'noindex,nofollow' }]
    }
  },

  async mounted() {
    await this.fetchDatas()

    this.customizeForDevice()

    this.initLottieFiles()

    this.cardsAnimation()

    this.initScrollMagic()
  },

  methods: {
    async fetchDatas() {
      this.$axios
        .$get(`${process.env.API_URL_SITE}/qna`)
        .then((response) => {
          this.QNA = response.data
        })
        .then((response) => this.setConfigAccordion())

      const getCards = await this.$axios.$get(
        `${process.env.API_URL_SITE}/how_get_card`
      )
      const speedPoints = await this.$axios.$get(
        `${process.env.API_URL_SITE}/loyalty_four`
      )
      const cardBlock = await this.$axios.$get(
        `${process.env.API_URL_SITE}/loyalty_three`
      )

      const cashback = await this.$axios.$get(
        `${process.env.API_URL_SITE}/loyalty_one`
      )

      this.getCards = getCards.data
      this.cardBlock = cardBlock.data
      this.speedPoints = speedPoints.data
      this.cashback = cashback.data
    },

    setConfigAccordion() {
      const answers = document.querySelectorAll(
        '.loyalty-page__accordion-answer'
      )
      // console.log('answers:', answers)
      answers.forEach((a) => this.answers.push(a))

      answers.forEach((h) => {
        this.accordionHeights.push(h.offsetHeight + 36)
        h.style.height = `0px`
      })

      this.answers[0].parentNode.classList.add('active')
      this.answers[0].style.height = `${this.accordionHeights[0]}px`
    },

    initLottieFiles() {
      this.prevIconLottiefile = window.lottie.loadAnimation({
        container: this.$refs.cprev,
        loop: false,
        autoplay: false,
        animationData: animeData
      })

      this.nextIconLottiefile = window.lottie.loadAnimation({
        container: this.$refs.cnext,
        loop: false,
        autoplay: false,
        animationData: animeData
      })

      window.lottie.loadAnimation({
        name: 'cardsData',
        container: this.$refs.cardsData,
        loop: false,
        autoplay: false,
        animationData: cardsData
      })

      window.lottie.loadAnimation({
        name: 'mobileLevitate',
        container: this.$refs.ml,
        loop: true,
        autoplay: true,
        path: `/lottiefiles/levitate/mobile-levitate-${this.$i18n.locale}.json`
      })
    },

    initScrollMagic() {
      const browserSize = window.innerWidth

      const dur = browserSize > 578 ? 900 : 700

      const mobileScrollPx = window.innerWidth > 500 ? 500 : 800

      const ctrl = new window.ScrollMagic.Controller()

      const info = document.querySelector('.get-points__info')
      const items = document.querySelectorAll('.get-points__list-item')

      let swipePx

      if (window.innerWidth >= 1600) {
        swipePx = 400
      } else {
        swipePx = 300
      }

      if (window.innerWidth < 576) {
        swipePx = 250
      }

      let counter = 1

      const mobileAddScrollPx = window.innerWidth < 576 ? 1000 : 0

      window.addEventListener('scroll', (e) => {
        items.forEach((item, index) => {
          item.classList.remove('active')
          if (counter === index + 1) {
            item.classList.add('active')
          }
        })

        if (
          window.scrollY > mobileScrollPx + 200 &&
          window.scrollY < mobileScrollPx + 400
        ) {
          counter = 1
        }

        if (
          window.scrollY > mobileScrollPx + 400 &&
          window.scrollY < mobileScrollPx + 600
        ) {
          counter = 2
        }

        if (
          window.scrollY > mobileScrollPx + 600 &&
          window.scrollY < mobileScrollPx + 800
        ) {
          counter = 3
        }

        if (
          window.scrollY > mobileScrollPx + 800 &&
          window.scrollY < mobileScrollPx + 1000
        ) {
          counter = 4
        }

        if (window.scrollY > mobileScrollPx + 1200) {
          counter = 5
        }

        if (window.scrollY > mobileScrollPx + 200) {
          info.style.transform = `translateY(${-swipePx * counter}px)`
        }
      })

      new window.ScrollMagic.Scene({
        triggerElement: this.$refs.points,
        triggerHook: 0,
        duration: dur + mobileAddScrollPx
      })
        .setPin(this.$refs.points)
        .addTo(ctrl)
    },

    accordionToggler(e) {
      const id = e.target.getAttribute('data-id')

      this.accordionHeights.forEach((h, idx) => {
        if (Number(id) === idx + 1) {
          if (!this.answers[idx].parentNode.classList.contains('active')) {
            this.answers.forEach((i) => {
              i.parentNode.classList.remove('active')
              i.style.height = `0px`
            })

            this.answers[idx].parentNode.classList.add('active')
            this.answers[idx].style.height = `${h}px`
          } else {
            this.answers[idx].parentNode.classList.remove('active')
            this.answers[idx].style.height = `0px`
          }
        }
      })
    },

    slideToNext() {
      this.$refs.vsc.next()
    },
    slideToPrev() {
      this.$refs.vsc.prev()
    },

    cardsAnimation() {
      window.lottie.setDirection(1)
      window.lottie.play('cardsData')
    },

    // Lottie files
    startPrevIconLottiefile() {
      this.prevIconLottiefile.setDirection(1)
      this.prevIconLottiefile.play()
    },

    endPrevIconLottiefile() {
      this.prevIconLottiefile.setDirection(-1)
      this.prevIconLottiefile.play()
    },

    startNextIconLottiefile() {
      this.nextIconLottiefile.setDirection(1)
      this.nextIconLottiefile.play()
    },

    endNextIconLottiefile() {
      this.nextIconLottiefile.setDirection(-1)
      this.nextIconLottiefile.play()
    },

    customizeForDevice() {
      const ua = navigator.userAgent
      const checker = {
        iphone: ua.match(/(iPhone|iPod|iPad)/),
        android: ua.match(/Android/)
      }
      if (checker.iphone) {
        this.checkerDevice = `${process.env.APPSTORE_URI}`
        this.isDevice = 'ios'
      } else if (checker.android) {
        this.checkerDevice = `${process.env.GOOGLEPLAY_URI}`
        this.isDevice = 'android'
      } else {
        this.checkerDevice = `https://korzinka.uz/app/${this.$i18n.locale}`
      }
    }
  }
}
