
import CouponChecker from './CouponChecker.vue'
import NewsForm from './NewsForm.vue'

export default {
  components: {
    CouponChecker,
    NewsForm
  },
  props: {
    post: {
      type: Object,
      default: () => ({})
    },
    coupons: {
      type: Array,
      default: () => []
    },
    showWidget: {
      type: Boolean,
      default: false
    },
    showForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      detailText: '',
      segments: [],
      segmentWithForm1: [],
      segmentWithForm2: []
    }
  },
  watch: {
    'post.detail_text'() {
      this.updateDetailText()
    },
    showForm() {
      this.updateDetailText()
    }
  },
  methods: {
    updateDetailText() {
      if (this.post.detail_text) {
        this.detailText =
          this.post.detail_text.includes('[FORM]') && !this.showForm
            ? this.post.detail_text.replace(/\[FORM\]/g, '')
            : this.post.detail_text
        this.segments = this.detailText.split('[WIDGET]')
      } else {
        this.detailText = ''
        this.segments = ['', '']
      }
    },
    emitToParent(payload) {
      this.$emit('get-coupons', payload)
    }
  },
  mounted() {
    this.updateDetailText()

    if (this.segments.length > 1) {
      if (this.segments[0].includes('[FORM]')) {
        this.segmentWithForm1 = this.segments[0].split('[FORM]')
      }
      if (this.segments[1].includes('[FORM]')) {
        this.segmentWithForm2 = this.segments[1].split('[FORM]')
      }
    } else {
      if (this.segments[0].includes('[FORM]')) {
        this.segmentWithForm1 = this.segments[0].split('[FORM]')
      }
    }
  }
}
