
export default {
  name: 'NewsLayout',
  props: {
    about: {
      type: Boolean,
      Required: false,
      default: false
    }
  },
  data: () => ({
    posts: [],
    slicedArray: [],
    loading: false,
    types: {
      row: 'image, list-item-two-line, text'
    }
  }),

  mounted() {
    this.fetchDatas()
  },

  methods: {
    async fetchDatas() {

      const { data } = await this.$axios.$get(
        `${process.env.API_URL_SITE}/news/list${ this.about ? '/category-10': ''}`
      )

      data.items.ru.splice(3)
      data.items.en.splice(3)
      data.items.uz.splice(3)

      this.posts = data.items

      if (data.items) {
        setTimeout(() => {
          this.loading = true
        }, 1000)
      }
    }
  }
}
