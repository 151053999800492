
export default {
  name: 'BestOfferLayout',

  data() {
    return {
      products: [],
      loading: false,
      types: {
        row: 'image, paragraph, chip'
      }
    }
  },

  async mounted() {
    await this.fetchDatas()
  },

  methods: {
    async fetchDatas() {
      try {
        const firstCategory = await this.$axios.$get(`mobile/catalogs-list`)
        const bestOffersID = firstCategory.data[0].categories[0].id

        const response = await this.$axios.$get(
          `/catalogs/categories/${bestOffersID}`
        )

        this.products = response.data.products.splice(0, 5)

        if (this.products.length) {
          setTimeout(() => {
            this.loading = true
          }, 1000)
        }
      } catch (e) {}
    }
  }
}
