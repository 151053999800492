
export default {
  name: 'PostBuilder',

  props: {
    posts: {
      type: [Array, Object],
      required: true
    }
  },

  methods: {
    colBuilder(post) {
      let colNumber

      switch (post.poster_type) {
        case 'standard':
          colNumber = 3
          break
        case 'middle':
          colNumber = 2
          break
        default:
          colNumber = 1
      }

      return colNumber
    }
  }
}
