
export default {
  name: 'StaticCatalogSinglePage',

  async asyncData({ app, $axios, route, env }) {
    const locale = app.i18n.locale
    const id = route.params.id
    let seoResponse, catalog, shareData, seo, faq
    try {
      const { data } = await $axios.$get(
        `/permanent-promotions/${Number(route.params.id)}`
      )

      catalog = data
      shareData = data ? { name: data[`title_${locale}`] } : null
    } catch (e) {}

    try {
      seoResponse = await $axios.$post(`${env.API_URL_SITE}/seo`, {
        url: `/catalog/static/${id}`,
        project: 'korzinka.uz'
      })

      seo = seoResponse?.data?.items
      faq = seoResponse?.data?.items?.[locale]?.[0]?.QNA
    } catch (e) {}

    return {
      catalog,
      shareData,
      seo,
      faq
    }
  },

  data() {
    return {
      isSubscribe: false,
      modifiedDescription: '',
      shareData: {},
      catalog: null,
      seo: null,
      faq: null
    }
  },

  head() {
    const baseUrl = 'https://korzinka.uz'
    const id = this.$route.params.id
    const locale = this.$i18n.locale
    let url = ''
    const scripts = []

    if (locale === 'ru') {
      url = `${baseUrl}/catalog/static/${id}`
    } else {
      url = `${baseUrl}/${locale}/catalog/static/${id}`
    }

    if (this?.faq.length) {
      scripts.push({
        hid: 'ldjson-schema',
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: this.faq.map((item) => ({
            '@type': 'Question',
            name: item.question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: item.answer
            }
          }))
        }
      })
    }

    return {
      title:
        this.seo?.[this.$i18n.locale]?.[0]?.title ||
        `Korzinka | ${this.$translatable(this.catalog || {}, 'title')}`,
      meta: [
        { hid: 'og:type', property: 'og:type', content: 'website' },
        {
          hid: 'description',
          name: 'description',
          content:
            this.seo?.[this.$i18n.locale]?.[0]?.description ||
            this.catalog?.sub_title_ru ||
            ''
        },
        {
          hid: 'og-title',
          property: 'og:title',
          content:
            this.seo?.[this.$i18n.locale]?.[0]?.og_title ||
            `Korzinka | ${this.$translatable(this.catalog || {}, 'title')}`
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content:
            this.seo?.[this.$i18n.locale]?.[0]?.og_description ||
            this.catalog?.sub_title_ru ||
            ''
        },
        {
          hid: 'og-url',
          property: 'og:url',
          content:
            this.seo?.[this.$i18n.locale]?.[0]?.og_url ||
            this.catalog?.promotion_url ||
            ''
        },
        {
          hid: 'og-image',
          property: 'og:image',
          content:
            this.seo?.[this.$i18n.locale]?.[0]?.og_img ||
            this.catalog?.single_image_url ||
            ''
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: url
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/catalog/static/${id}`,
          hreflang: 'ru'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/uz/catalog/static/${id}`,
          hreflang: 'uz'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/en/catalog/static/${id}`,
          hreflang: 'en'
        }
      ],
      script: [...scripts]
    }
  },

  mounted() {
    this.modifiedDescription = this.$translatable(
      this.catalog || {},
      'description'
    )
  },

  methods: {
    subscribeHandler() {
      this.isSubscribe = !this.isSubscribe
    }
  }
}
