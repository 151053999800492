
import animeData from '~/static/lottiefiles/circle.json'
export default {
  name: 'AboutAwardsLayout',

  data: (vm) => ({
    settings: {
      slidesToShow: 1,
      infinite: true,
      arrows: false,
      dots: false,
      draggable: false
    },

    awards: []
  }),

  async fetch() {
    const { data } = await this.$axios.$get(
      `${process.env.API_URL_SITE}/awards/list`
    )

    this.awards = data.items
  },

  mounted() {
    this.initLottieFiles()
  },

  methods: {
    initLottieFiles() {
      this.prevIconLottiefile = window.lottie.loadAnimation({
        container: this.$refs.prev,
        loop: false,
        autoplay: false,
        animationData: animeData
      })

      this.nextIconLottiefile = window.lottie.loadAnimation({
        container: this.$refs.next,
        loop: false,
        autoplay: false,
        animationData: animeData
      })
    },

    slideToNext() {
      this.$refs.vsc.next()
    },

    slideToPrev() {
      this.$refs.vsc.prev()
    },

    // Lottie files
    startPrevIconLottiefile() {
      this.prevIconLottiefile.setDirection(1)
      this.prevIconLottiefile.play()
    },

    endPrevIconLottiefile() {
      this.prevIconLottiefile.setDirection(-1)
      this.prevIconLottiefile.play()
    },

    startNextIconLottiefile() {
      this.nextIconLottiefile.setDirection(1)
      this.nextIconLottiefile.play()
    },

    endNextIconLottiefile() {
      this.nextIconLottiefile.setDirection(-1)
      this.nextIconLottiefile.play()
    }
  }
}
