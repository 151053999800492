
import animeData from '~/static/lottiefiles/circle.json'
import { sortBanners } from '~/utils/sortSpecBanners'

export default {
  name: 'SalesLayout',

  data: () => ({
    settings: {
      slidesToShow: 3,
      arrows: false,
      dots: false,
      draggable: false,
      infinite: false,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    },

    prevIconLottiefile: null,
    nextIconLottiefile: null,
    sortedSpecBanners: [],
    catalogs: [],
    sizeCatalogs: []
  }),

  async mounted() {
    this.initLottiefiles()
    await this.fetchDatas()
  },

  methods: {
    async fetchDatas() {
      try {
        const response = await this.$axios.$get('/catalogs/banners')
        this.catalogs = response
        this.sortedSpecBanners = sortBanners(this.catalogs.staticCatalogs)
        this.sizeCatalogs =
          this.catalogs.standardCatalogs.length +
          this.catalogs.staticCatalogs.length
        if (this.catalogs.standardCatalogs[0] === null) {
          this.sizeCatalogs--
        }
      } catch (error) {
        throw error
      }
    },

    initLottiefiles() {
      this.prevIconLottiefile = window.lottie.loadAnimation({
        container: this.$refs.cprev,
        loop: false,
        autoplay: false,
        animationData: animeData
      })

      this.nextIconLottiefile = window.lottie.loadAnimation({
        container: this.$refs.cnext,
        loop: false,
        autoplay: false,
        animationData: animeData
      })
    },

    slideToNext() {
      this.$refs.vsc.next()
    },
    slideToPrev() {
      this.$refs.vsc.prev()
    },

    // Lottie files
    startPrevIconLottiefile() {
      this.prevIconLottiefile.setDirection(1)
      this.prevIconLottiefile.play()
    },

    endPrevIconLottiefile() {
      this.prevIconLottiefile.setDirection(-1)
      this.prevIconLottiefile.play()
    },

    startNextIconLottiefile() {
      this.nextIconLottiefile.setDirection(1)
      this.nextIconLottiefile.play()
    },

    endNextIconLottiefile() {
      this.nextIconLottiefile.setDirection(-1)
      this.nextIconLottiefile.play()
    }
  }
}
