
import { nextTick } from 'vue';
export default {
  name: 'NewsPage',

  async asyncData({ app, env, $axios }) {
    const response = await $axios.$post(`${env.API_URL_SITE}/seo`, {
      url: '/news',
      project: "korzinka.uz"
    })

    const currentLocale = app.i18n.locale;

    return { seo: response?.data?.items || null, faq: response?.data?.items?.[currentLocale]?.[0]?.QNA || null }
  },

  data: () => ({
    categories: [],
    loading: false,
    types: {
      row: 'image, list-item-two-line, text'
    },

    seo: null,
    faq: null,

    posts: {
      ru: [],
      en: [],
      uz: []
    },

    currPage: 1,
    currCategoryId: 21,
    allPostsCount: 0
  }),

  head() {
    return {
      title: this.seo?.[this.$i18n.locale]?.[0]?.title,
      meta: [
        { hid: 'og:type', property: 'og:type', content: 'website' },
        {
          hid: 'description',
          name: 'description',
          content: this.seo?.[this.$i18n.locale]?.[0]?.description
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_url
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_title
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_description
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_img
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image'
        }
      ],

      link: [
        {
          rel: 'canonical',
          href: `https://korzinka.uz/${
            this.$i18n.locale === 'ru' ? '' : this.$i18n.locale + '/'
          }news`
        },
        { rel: 'alternate', href: `https://korzinka.uz/news`, hreflang: 'ru' },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/uz/news`,
          hreflang: 'uz'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/en/news`,
          hreflang: 'en'
        }
      ],
      script: [
        {
          hid: 'ldjson-schema',
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": this.faq ? this.faq.map(item => ({
              "@type": "Question",
              "name": item.question,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": item.answer
              }
            })) : []
          }
        }
      ]
    }
  },

  async mounted() {
  try {
    // Загрузите категории и посты
    await this.fetchDatas();

    // Проверяем параметр "from"
    const fromParam = this.$route.query.from; // Получаем параметр "from"
    const specialParam = 'about'; // Укажите ваш параметр

    // Если параметр "from=about" присутствует
    if (fromParam === specialParam) {
      await this.$nextTick();

      // Устанавливаем активный таб и загружаем данные для него
      await this.fetchCategoryHandler(null, '10');
    } else {
      // Оставляем дефолтный таб
      await this.$nextTick();

      // Установите активный класс для дефолтного таба
      const defaultTab = this.$el.querySelector('.news-page__tab');
      if (defaultTab) {
        defaultTab.classList.add('activable');
      }
    }
  } catch (error) {
    console.error('Ошибка в mounted:', error);
  }
},




  methods: {
    async fetchDatas() {
  try {
    // Загрузка категорий
    const categories = await this.$axios.$get(
      `${process.env.API_URL_SITE}/categories/list`
    );

    this.categories = categories.data;

    // Загрузка постов для текущей категории
    const posts = await this.$axios.$get(
      `${process.env.API_URL_SITE}/news/list/category-${this.currCategoryId}`
    );

    this.posts.ru = posts.data.items.ru;
    this.posts.en = posts.data.items.en;
    this.posts.uz = posts.data.items.uz;

    this.allPostsCount = posts.data.pager.all_elements_count || 0;

    // Устанавливаем состояние загрузки
    setTimeout(() => {
      this.loading = true;
    }, 1000);
  } catch (e) {
    console.error('Ошибка в fetchDatas:', e);
  }
},

async fetchCategoryHandler(e = null, id) {
  try {
    this.loading = false;
    this.currPage = 1;
    this.currCategoryId = id;

    // Удаляем активный класс со всех табов
    const tabs = this.$el.querySelectorAll('.news-page__tab');
    tabs.forEach((t) => t.classList.remove('activable'));

    // Определяем текущий таб
    let curr;
    if (e) {
      curr = e.currentTarget; // Если вызвано через клик
    } else {
      curr = Array.from(tabs).find((tab) => tab.getAttribute('data-id') === id);
    }

    // Устанавливаем активный класс, если таб найден
    if (curr) {
      curr.classList.add('activable');
    } else {
      console.warn(`Таб с id ${id} не найден.`);
    }

    // Загружаем данные для категории
    const { data } = await this.$axios.$get(
      `${process.env.API_URL_SITE}/news/list/category-${this.currCategoryId}`
    );

    this.posts.ru = data.items.ru;
    this.posts.en = data.items.en;
    this.posts.uz = data.items.uz;

    this.allPostsCount = data.pager.all_elements_count || 0;

    setTimeout(() => {
      this.loading = true;
    }, 1000);
  } catch (e) {
    console.error('Ошибка в fetchCategoryHandler:', e);
  }
},



    async loadPostHandler() {
      this.currPage++

      const { data } = await this.$axios.$get(
        `${process.env.API_URL_SITE}/news/list/category-${this.currCategoryId}/page-${this.currPage}`
      )

      data.items.ru.forEach((i) => this.posts.ru.push(i))
      data.items.en.forEach((i) => this.posts.en.push(i))
      data.items.uz.forEach((i) => this.posts.uz.push(i))
    }
  }
}
