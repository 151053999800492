import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3eeba3a7&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eeba3a7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheAccordion: require('/home/korzinka/www/front/components/TheAccordion.vue').default,SeoBlock: require('/home/korzinka/www/front/components/SeoBlock.vue').default})
