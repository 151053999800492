
// import fetchMetaTags from '@/utils/FETCH_META_TAGS'

export default {
  name: 'PravilaProgrammyLoyalnosti',

  async asyncData({ app, env, $axios }) {
    const response = await $axios.$post(`${env.API_URL_SITE}/seo`, {
      url: '/pravila-programmy-loyalnosti',
      project: "korzinka.uz"
    })

    const currentLocale = app.i18n.locale;

    return { seo: response?.data?.items || null, faq: response?.data?.items?.[currentLocale]?.[0]?.QNA || null }
  },

  data: () => ({
    seo: null,
    faq: null,
    content: {}
  }),

  head() {
    return {
      title: this.seo[this.$i18n.locale][0].title,

      meta: [
        { hid: 'og:type', property: 'og:type', content: 'website' },
        {
          hid: 'description',
          name: 'description',
          content: this.seo[this.$i18n.locale][0].description
        },
        {
          hid: 'og-title',
          property: 'og:title',
          content: this.seo[this.$i18n.locale][0].og_title
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content: this.seo[this.$i18n.locale][0].og_description
        },
        {
          hid: 'og-url',
          property: 'og:url',
          content: this.seo[this.$i18n.locale][0].og_url
        },

        {
          hid: 'og-image',
          property: 'og:image',
          content: this.seo[this.$i18n.locale][0].og_img
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `https://korzinka.uz/${
            this.$i18n.locale === 'ru' ? '' : this.$i18n.locale + '/'
          }pravila-programmy-loyalnosti`
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/pravila-programmy-loyalnosti`,
          hreflang: 'ru'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/uz/pravila-programmy-loyalnosti`,
          hreflang: 'uz'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/en/pravila-programmy-loyalnosti`,
          hreflang: 'en'
        }
      ],
      script: [
        {
          hid: 'ldjson-schema',
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": this.faq.map(item => ({
              "@type": "Question",
              "name": item.question,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": item.answer
              }
            }))
          }
        }
      ]
    }
  },

  mounted() {
    this.fetchData()

    // try {
    //   const { items } = await fetchMetaTags('/pravila-programmy-loyalnosti')
    //   this.seo = items[this.$i18n.locale][0]
    // } catch (error) {
    //   console.log(error)
    // }
  },

  methods: {
    async fetchData() {
      const { data } = await this.$axios.$get(
        `${process.env.API_URL_SITE}/loyalty_program`
      )
      this.content = data.item
    }
  }
}
