
export default {
  name: 'StoresPage',

  async asyncData({ app, env, $axios, query }) {
    const response = await $axios.$post(`${env.API_URL_SITE}/seo`, {
      url: '/stores',
      project: 'korzinka.uz'
    })

    const currentLocale = app.i18n.locale

    return {
      seo: response?.data?.items || null,
      faq: response?.data?.items?.[currentLocale]?.[0]?.QNA || null
    }
  },

  data: () => ({
    activeId: null,
    regionSeoBlock: {
      ru: {
        route: '',
        h1: '',
        title: '',
        text: ''
      },

      uz: {
        route: '',
        h1: '',
        title: '',
        text: ''
      },
      en: {
        route: '',
        h1: '',
        title: '',
        text: ''
      }
    },

    seo: null,
    faq: null,

    isSettings: false,

    mapOptions: {
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: true
    },

    zoom: 13,

    clusterStyle: [
      {
        url: require('@/assets/img/map-detail/clusters/cluster.svg'),
        width: 105,
        height: 100,
        fontFamily: 'Korzinka',
        fontStyle: 'italic',
        fontWeight: '700',
        textSize: 40,
        textColor: '#fff'
      }
    ],

    locationCard: {},
    isShowLocationCard: false,

    isMobileShowWidgets: true,

    searchQueryStr: '',

    locations: [],

    originLocations: [],

    chooseNumber: 0,

    // Filter
    types: [],
    services: [],
    regions: [],

    fromYou: '',

    mobileCard: false,

    // Current location
    currentLocation: { lat: 41.311081, lng: 69.240562 },
    userLocation: { lat: 0, lng: 0 },
    clickedCurrentLocationButton: false,

    widgetsCategoriesSizeStore: 0,

    widgetsCategoriesHeight: 0,

    bodyFixed: false
  }),

  head() {
    const scripts = []
    if (this?.faq.length) {
      scripts.push({
        hid: 'ldjson-schema',
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: this.faq.map((item) => ({
            '@type': 'Question',
            name: item.question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: item.answer
            }
          }))
        }
      })
    }
    return {
      title: this.seo?.[this.$i18n.locale]?.[0]?.title || '',

      meta: [
        { hid: 'og:type', property: 'og:type', content: 'website' },
        {
          hid: 'description',
          name: 'description',
          content: this.seo?.[this.$i18n.locale]?.[0]?.description || ''
        },
        {
          hid: 'og-title',
          property: 'og:title',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_title || ''
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_description || ''
        },
        {
          hid: 'og-url',
          property: 'og:url',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_url || ''
        },

        {
          hid: 'og-image',
          property: 'og:image',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_img || ''
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image'
        }
      ],

      bodyAttrs: {
        class: this.bodyFixed ? 'fixed' : ''
      },

      link: [
        {
          rel: 'canonical',
          href: `https://korzinka.uz/${
            this.$i18n.locale === 'ru' ? '' : this.$i18n.locale + '/'
          }stores`
        },

        {
          rel: 'alternate',
          href: `https://korzinka.uz/stores`,
          hreflang: 'ru'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/uz/stores`,
          hreflang: 'uz'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/en/stores`,
          hreflang: 'en'
        }
      ],
      script: [...scripts]
    }
  },

  watch: {
    '$route.query': 'handleQueryChange'
  },

  mounted() {
    if (window.innerWidth < 576) {
      this.mobileCard = true
    }

    this.initTabActive()

    this.fetchData().then(async () => {
      if (this.paramIsExist('store')) {
        this.setActiveId()

        this.showCardLocation(this.activeId)
      }

      if (
        this.paramIsExist('q') ||
        this.paramIsExist('category[]') ||
        this.paramIsExist('service[]') ||
        this.paramIsExist('region[]')
      ) {
        const { data } = await this.$axios.$get(
          `${process.env.API_URL_SITE}/shop_search/?${new URLSearchParams(
            window.location.search
          ).toString()}`
        )

        if (this.paramIsExist('category[]')) {
          const ids = this.$route.query['category[]']
          const types = this.$el.querySelectorAll('.search-settings__type')
          types.forEach((el) => {
            if (ids.includes(el.dataset.id)) el.classList.add('active')
          })
        }

        if (this.paramIsExist('service[]')) {
          const ids = this.$route.query['service[]']
          const services = this.$el.querySelectorAll(
            '.search-settings__service'
          )
          services.forEach((el) => {
            if (ids.includes(el.dataset.id))
              el.querySelector('input').checked = true
          })
        }

        if (this.paramIsExist('region[]')) {
          const ids = this.$route.query['region[]']
          const services = this.$el.querySelectorAll('.search-settings__city')
          services.forEach((el) => {
            if (ids.includes(el.dataset.id)) el.classList.add('active')
          })
        }

        if (this.paramIsExist('q')) {
          const q = this.$route.query.q
          // console.log(q);
          this.searchQueryStr = q || ''
        }

        this.$i18n.localeCodes.forEach((locale) => {
          this.regionSeoBlock[locale].route =
            data.items?.[locale]?.[0]?.region?.route
          this.regionSeoBlock[locale].h1 = data.items?.[locale]?.[0]?.region?.h1
          this.regionSeoBlock[locale].title =
            data.items?.[locale]?.[0]?.region?.title
          this.regionSeoBlock[locale].text =
            data.items?.[locale]?.[0]?.region?.text
        })

        if (!data.items[0]) {
          this.locations = data.items
        } else {
          this.originLocations = data.items
        }

        this.chooseCounter()
      }
    })
  },

  methods: {
    handleQueryChange(newQuery, oldQuery) {
      if (newQuery?.store) {
        this.setActiveId()

        this.showCardLocation(newQuery.store)
      }
    },
    paramIsExist(param) {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.has(param)
    },
    setActiveId() {
      const urlParams = new URLSearchParams(window.location.search)
      this.activeId = urlParams.get('store')
    },
    async fetchData() {
      const locations = await this.$axios.$get(
        `${process.env.API_URL_SITE}/shops/list`
      )

      const types = await this.$axios.$get(
        `${process.env.API_URL_SITE}/shop_cat`
      )

      const services = await this.$axios.$get(
        `${process.env.API_URL_SITE}/shop_services`
      )

      console.log(services)
      const regions = await this.$axios.$get(
        `${process.env.API_URL_SITE}/shop_regions`
      )

      this.locations = locations.data.items
      this.originLocations = locations.data.items
      this.types = types.data
      this.services = services.data
      this.regions = regions.data
    },

    markerMapChecker(type) {
      let marker = ''
      switch (type) {
        case 'Flo':
          marker = `<svg width="77" height="98" viewBox="0 0 77 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.5">
                      <g filter="url(#filter0_f_3029_305518)">
                      <ellipse cx="38.8516" cy="71.0293" rx="16" ry="4.5" fill="black"/>
                      </g>
                      <g opacity="0.4" filter="url(#filter1_f_3029_305518)">
                      <ellipse cx="38.8516" cy="69.5293" rx="9" ry="3" fill="black"/>
                      </g>
                      </g>
                      <path d="M64.8513 26.9257C64.8513 45.9577 38.9257 70.5279 38.9257 70.5279C38.9257 70.5279 13 45.9577 13 26.9257C13 12.6073 24.6073 1 38.9257 1C53.244 1 64.8513 12.6073 64.8513 26.9257Z" fill="white"/>
                      <path d="M38.9257 70.5279C39.2696 70.8908 39.2698 70.8906 39.27 70.8904L39.271 70.8894L39.2749 70.8858L39.2895 70.8719L39.3462 70.8176C39.396 70.7698 39.4696 70.6989 39.5653 70.6058C39.7568 70.4196 40.037 70.1448 40.3932 69.7885C41.1056 69.076 42.1223 68.0378 43.3418 66.7328C45.7804 64.1234 49.0328 60.4443 52.2864 56.1665C55.539 51.8902 58.8001 47.0061 61.2498 41.987C63.697 36.9729 65.3513 31.7902 65.3513 26.9257C65.3513 12.3312 53.5201 0.5 38.9257 0.5C24.3312 0.5 12.5 12.3312 12.5 26.9257C12.5 31.7902 14.1543 36.9729 16.6015 41.987C19.0512 47.0061 22.3123 51.8902 25.5649 56.1665C28.8185 60.4443 32.0709 64.1234 34.5095 66.7328C35.729 68.0378 36.7457 69.076 37.4581 69.7885C37.8143 70.1448 38.0945 70.4196 38.286 70.6058C38.3817 70.6989 38.4553 70.7698 38.5051 70.8176L38.5618 70.8719L38.5764 70.8858L38.5803 70.8894L38.5813 70.8904C38.5815 70.8906 38.5817 70.8908 38.9257 70.5279ZM38.9257 70.5279L39.2696 70.8908L38.9257 71.2167L38.5817 70.8908L38.9257 70.5279Z" stroke="black" stroke-opacity="0.12"/>
                      <path d="M40.648 20.824C43.4 20.696 45 20.824 47.752 20.824C49.992 20.824 48.04 16.6 45.032 16.6C42.472 16.6 39.912 16.6 37.352 16.76C36.36 15.928 35.016 15.672 34.728 16.76C33.96 19.64 33.128 22.904 32.424 26.04L31.688 26.104C30.152 26.264 30.472 27.864 31.848 28.792C31.208 31.864 30.792 34.616 30.792 36.472C30.792 38.616 34.728 40.792 34.92 38.232C35.144 35.256 35.592 32.312 36.2 29.368C38.664 29.304 41.512 29.368 43.56 29.368C45.8 29.368 44.68 25.624 40.84 25.624C39.624 25.624 38.312 25.656 37 25.72C37.416 23.896 37.896 22.072 38.376 20.216C39.08 20.6 39.848 20.856 40.648 20.824Z" fill="#FF6B00"/>
                      <defs>
                      <filter id="filter0_f_3029_305518" x="0.851562" y="44.5293" width="76" height="53" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feGaussianBlur stdDeviation="11" result="effect1_foregroundBlur_3029_305518"/>
                      </filter>
                      <filter id="filter1_f_3029_305518" x="19.8516" y="56.5293" width="38" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_3029_305518"/>
                      </filter>
                      </defs>
                    </svg>
                  `
          break
        case 'Korzinka':
          marker = `<svg width="77" height="98" viewBox="0 0 77 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.5">
                      <g filter="url(#filter0_f_3029_305488)">
                      <ellipse cx="38.8516" cy="71.0293" rx="16" ry="4.5" fill="black"/>
                      </g>
                      <g opacity="0.4" filter="url(#filter1_f_3029_305488)">
                      <ellipse cx="38.8516" cy="69.5293" rx="9" ry="3" fill="black"/>
                      </g>
                      </g>
                      <path d="M64.8513 26.9257C64.8513 45.9577 38.9257 70.5279 38.9257 70.5279C38.9257 70.5279 13 45.9577 13 26.9257C13 12.6073 24.6073 1 38.9257 1C53.244 1 64.8513 12.6073 64.8513 26.9257Z" fill="white"/>
                      <path d="M38.9257 70.5279C39.2696 70.8908 39.2698 70.8906 39.27 70.8904L39.271 70.8894L39.2749 70.8858L39.2895 70.8719L39.3462 70.8176C39.396 70.7698 39.4696 70.6989 39.5653 70.6058C39.7568 70.4196 40.037 70.1448 40.3932 69.7885C41.1056 69.076 42.1223 68.0378 43.3418 66.7328C45.7804 64.1234 49.0328 60.4443 52.2864 56.1665C55.539 51.8902 58.8001 47.0061 61.2498 41.987C63.697 36.9729 65.3513 31.7902 65.3513 26.9257C65.3513 12.3312 53.5201 0.5 38.9257 0.5C24.3312 0.5 12.5 12.3312 12.5 26.9257C12.5 31.7902 14.1543 36.9729 16.6015 41.987C19.0512 47.0061 22.3123 51.8902 25.5649 56.1665C28.8185 60.4443 32.0709 64.1234 34.5095 66.7328C35.729 68.0378 36.7457 69.076 37.4581 69.7885C37.8143 70.1448 38.0945 70.4196 38.286 70.6058C38.3817 70.6989 38.4553 70.7698 38.5051 70.8176L38.5618 70.8719L38.5764 70.8858L38.5803 70.8894L38.5813 70.8904C38.5815 70.8906 38.5817 70.8908 38.9257 70.5279ZM38.9257 70.5279L39.2696 70.8908L38.9257 71.2167L38.5817 70.8908L38.9257 70.5279Z" stroke="black" stroke-opacity="0.12"/>
                      <path d="M42.368 38.552C43.872 40.44 49.28 39.064 47.872 37.304C45.728 34.392 43.104 30.968 41.312 27.704C44.416 25.368 48.128 22.424 50.24 19.8C51.424 18.392 47.872 15.096 46.688 16.504C43.232 20.6 40.992 22.808 37.376 26.072C36.544 26.84 36.128 27.672 36.576 28.568C38.016 31.352 40.224 35.832 42.368 38.552ZM29.344 36.12C28.928 38.168 33.056 40.632 33.44 38.84L37.568 19.48C37.984 17.432 33.856 14.968 33.472 16.76L29.344 36.12Z" fill="#E4002B"/>
                      <defs>
                      <filter id="filter0_f_3029_305488" x="0.851562" y="44.5293" width="76" height="53" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feGaussianBlur stdDeviation="11" result="effect1_foregroundBlur_3029_305488"/>
                      </filter>
                      <filter id="filter1_f_3029_305488" x="19.8516" y="56.5293" width="38" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_3029_305488"/>
                      </filter>
                      </defs>
                    </svg>
                  `
          break
        case 'Redtag':
          marker = `<svg width="77" height="98" viewBox="0 0 77 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.5">
                      <g filter="url(#filter0_f_3029_305512)">
                      <ellipse cx="38.8516" cy="71.0293" rx="16" ry="4.5" fill="black"/>
                      </g>
                      <g opacity="0.4" filter="url(#filter1_f_3029_305512)">
                      <ellipse cx="38.8516" cy="69.5293" rx="9" ry="3" fill="black"/>
                      </g>
                      </g>
                      <path d="M64.8513 26.9257C64.8513 45.9577 38.9257 70.5279 38.9257 70.5279C38.9257 70.5279 13 45.9577 13 26.9257C13 12.6073 24.6073 1 38.9257 1C53.244 1 64.8513 12.6073 64.8513 26.9257Z" fill="white"/>
                      <path d="M38.9257 70.5279C39.2696 70.8908 39.2698 70.8906 39.27 70.8904L39.271 70.8894L39.2749 70.8858L39.2895 70.8719L39.3462 70.8176C39.396 70.7698 39.4696 70.6989 39.5653 70.6058C39.7568 70.4196 40.037 70.1448 40.3932 69.7885C41.1056 69.076 42.1223 68.0378 43.3418 66.7328C45.7804 64.1234 49.0328 60.4443 52.2864 56.1665C55.539 51.8902 58.8001 47.0061 61.2498 41.987C63.697 36.9729 65.3513 31.7902 65.3513 26.9257C65.3513 12.3312 53.5201 0.5 38.9257 0.5C24.3312 0.5 12.5 12.3312 12.5 26.9257C12.5 31.7902 14.1543 36.9729 16.6015 41.987C19.0512 47.0061 22.3123 51.8902 25.5649 56.1665C28.8185 60.4443 32.0709 64.1234 34.5095 66.7328C35.729 68.0378 36.7457 69.076 37.4581 69.7885C37.8143 70.1448 38.0945 70.4196 38.286 70.6058C38.3817 70.6989 38.4553 70.7698 38.5051 70.8176L38.5618 70.8719L38.5764 70.8858L38.5803 70.8894L38.5813 70.8904C38.5815 70.8906 38.5817 70.8908 38.9257 70.5279ZM38.9257 70.5279L39.2696 70.8908L38.9257 71.2167L38.5817 70.8908L38.9257 70.5279Z" stroke="black" stroke-opacity="0.12"/>
                      <path d="M33.76 38.84C34.112 35.704 34.624 32.408 35.2 29.368C35.68 29.976 36.512 30.232 38.176 30.264C39.232 33.112 40.928 36.44 41.792 37.816C43.68 40.888 49.056 39.192 47.648 37.464C45.952 35.352 43.968 32.344 42.752 29.624C46.304 28.472 48.736 25.848 48.736 22.744C48.736 15.256 38.816 15.416 32.576 17.048C30.976 17.464 31.52 19.224 32.864 20.312L32.832 20.408C31.264 26.008 30.304 31.384 29.632 36.12C29.312 38.328 33.568 40.664 33.76 38.84ZM35.872 26.36C36.16 25.112 36.448 23.992 36.736 22.968C36.928 22.264 36.576 21.496 35.968 20.888C39.456 19.704 44.128 19.832 44.128 23.128C44.128 25.336 42.016 26.488 38.464 26.488C37.472 26.488 36.672 26.456 35.872 26.36Z" fill="#FF5959"/>
                      <defs>
                      <filter id="filter0_f_3029_305512" x="0.851562" y="44.5293" width="76" height="53" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feGaussianBlur stdDeviation="11" result="effect1_foregroundBlur_3029_305512"/>
                      </filter>
                      <filter id="filter1_f_3029_305512" x="19.8516" y="56.5293" width="38" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_3029_305512"/>
                      </filter>
                      </defs>
                    </svg>
                  `
          break
        case 'Smart':
          marker = `<svg width="77" height="98" viewBox="0 0 77 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.5">
                      <g filter="url(#filter0_f_3029_305476)">
                      <ellipse cx="38.8516" cy="71.0293" rx="16" ry="4.5" fill="black"/>
                      </g>
                      <g opacity="0.4" filter="url(#filter1_f_3029_305476)">
                      <ellipse cx="38.8516" cy="69.5293" rx="9" ry="3" fill="black"/>
                      </g>
                      </g>
                      <path d="M64.8513 26.9257C64.8513 45.9577 38.9257 70.5279 38.9257 70.5279C38.9257 70.5279 13 45.9577 13 26.9257C13 12.6073 24.6073 1 38.9257 1C53.244 1 64.8513 12.6073 64.8513 26.9257Z" fill="white"/>
                      <path d="M38.9257 70.5279C39.2696 70.8908 39.2698 70.8906 39.27 70.8904L39.271 70.8894L39.2749 70.8858L39.2895 70.8719L39.3462 70.8176C39.396 70.7698 39.4696 70.6989 39.5653 70.6058C39.7568 70.4196 40.037 70.1448 40.3932 69.7885C41.1056 69.076 42.1223 68.0378 43.3418 66.7328C45.7804 64.1234 49.0328 60.4443 52.2864 56.1665C55.539 51.8902 58.8001 47.0061 61.2498 41.987C63.697 36.9729 65.3513 31.7902 65.3513 26.9257C65.3513 12.3312 53.5201 0.5 38.9257 0.5C24.3312 0.5 12.5 12.3312 12.5 26.9257C12.5 31.7902 14.1543 36.9729 16.6015 41.987C19.0512 47.0061 22.3123 51.8902 25.5649 56.1665C28.8185 60.4443 32.0709 64.1234 34.5095 66.7328C35.729 68.0378 36.7457 69.076 37.4581 69.7885C37.8143 70.1448 38.0945 70.4196 38.286 70.6058C38.3817 70.6989 38.4553 70.7698 38.5051 70.8176L38.5618 70.8719L38.5764 70.8858L38.5803 70.8894L38.5813 70.8904C38.5815 70.8906 38.5817 70.8908 38.9257 70.5279ZM38.9257 70.5279L39.2696 70.8908L38.9257 71.2167L38.5817 70.8908L38.9257 70.5279Z" stroke="black" stroke-opacity="0.12"/>
                      <path d="M30.536 33.144C29.448 33.144 29.096 34.968 29.096 35.96C29.096 38.584 33.288 39.48 36.936 39.48C42.024 39.48 46.536 37.304 46.536 32.28C46.536 28.184 43.336 26.808 39.88 25.944C37.032 25.08 36.104 24.44 36.104 23.16C36.104 21.304 37.608 20.248 40.424 20.248C44.008 20.248 45 22.104 46.216 22.104C46.856 22.104 47.528 20.952 47.528 19.128C47.528 17.08 44.296 16.12 40.904 16.12C35.976 16.12 31.592 18.904 31.592 23.16C31.592 27.832 34.856 28.856 38.024 29.752C40.776 30.52 41.96 31.192 41.96 32.632C41.96 34.296 40.296 35.32 36.808 35.32C32.936 35.32 31.176 33.144 30.536 33.144Z" fill="#51AB42"/>
                      <defs>
                      <filter id="filter0_f_3029_305476" x="0.851562" y="44.5293" width="76" height="53" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feGaussianBlur stdDeviation="11" result="effect1_foregroundBlur_3029_305476"/>
                      </filter>
                      <filter id="filter1_f_3029_305476" x="19.8516" y="56.5293" width="38" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_3029_305476"/>
                      </filter>
                      </defs>
                  </svg>
                  `
          break
        case 'B2b':
          marker = `<svg width="77" height="98" viewBox="0 0 77 98" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.5">
<g filter="url(#filter0_f_7968_325966)">
<ellipse cx="38.8516" cy="71.0293" rx="16" ry="4.5" fill="black"/>
</g>
<g opacity="0.4" filter="url(#filter1_f_7968_325966)">
<ellipse cx="38.8516" cy="69.5293" rx="9" ry="3" fill="black"/>
</g>
</g>
<path d="M64.8513 26.9257C64.8513 45.9577 38.9257 70.5279 38.9257 70.5279C38.9257 70.5279 13 45.9577 13 26.9257C13 12.6073 24.6073 1 38.9257 1C53.244 1 64.8513 12.6073 64.8513 26.9257Z" fill="white"/>
<path d="M38.9257 70.5279C39.2696 70.8908 39.2698 70.8906 39.27 70.8904L39.271 70.8894L39.2749 70.8858L39.2895 70.8719L39.3462 70.8176C39.396 70.7698 39.4696 70.6989 39.5653 70.6058C39.7568 70.4196 40.037 70.1448 40.3932 69.7885C41.1056 69.076 42.1223 68.0378 43.3418 66.7328C45.7804 64.1234 49.0328 60.4443 52.2864 56.1665C55.539 51.8902 58.8001 47.0061 61.2498 41.987C63.697 36.9729 65.3513 31.7902 65.3513 26.9257C65.3513 12.3312 53.5201 0.5 38.9257 0.5C24.3312 0.5 12.5 12.3312 12.5 26.9257C12.5 31.7902 14.1543 36.9729 16.6015 41.987C19.0512 47.0061 22.3123 51.8902 25.5649 56.1665C28.8185 60.4443 32.0709 64.1234 34.5095 66.7328C35.729 68.0378 36.7457 69.076 37.4581 69.7885C37.8143 70.1448 38.0945 70.4196 38.286 70.6058C38.3817 70.6989 38.4553 70.7698 38.5051 70.8176L38.5618 70.8719L38.5764 70.8858L38.5803 70.8894L38.5813 70.8904C38.5815 70.8906 38.5817 70.8908 38.9257 70.5279ZM38.9257 70.5279L39.2696 70.8908L38.9257 71.2167L38.5817 70.8908L38.9257 70.5279Z" stroke="black" stroke-opacity="0.12"/>
<path d="M37.6 39.224C43.456 39.224 47.552 36.792 47.552 32.184C47.552 30.232 46.4 28.696 44.384 27.768C47.168 26.84 48.928 24.856 48.928 22.328C48.928 18.776 46.368 16.44 39.936 16.44C36.928 16.44 34.016 16.6 32.544 16.984C30.976 17.4 31.52 19.288 33.024 20.28L32.96 20.44C31.456 25.656 30.336 30.904 29.6 36.12C29.312 38.2 32.896 40.632 33.12 38.84L33.184 38.296C34.24 38.84 35.712 39.224 37.6 39.224ZM33.728 34.872C34.08 32.856 34.496 31.064 34.912 29.336C35.808 29.272 36.832 29.272 37.984 29.272C40.928 29.272 42.88 30.072 42.88 32.056C42.88 34.104 40.992 35.352 37.984 35.352C36.096 35.352 35.104 35.16 33.728 34.872ZM35.584 20.856C36.16 20.76 37.28 20.472 39.712 20.472C42.624 20.472 44.288 21.144 44.288 22.712C44.288 25.24 41.856 25.912 39.136 25.912C38.016 25.912 36.896 25.848 35.84 25.688C36.032 24.856 36.256 24.024 36.448 23.16C36.64 22.36 36.224 21.496 35.584 20.856Z" fill="#1B02A4"/>
<defs>
<filter id="filter0_f_7968_325966" x="0.851562" y="44.5293" width="76" height="53" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="11" result="effect1_foregroundBlur_7968_325966"/>
</filter>
<filter id="filter1_f_7968_325966" x="19.8516" y="56.5293" width="38" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_7968_325966"/>
</filter>
</defs>
</svg>
`
          break

        case 'korzinka-mahalla':
          marker = `
          <svg width="56" height="71" viewBox="0 0 56 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_dd_903_3314)">
            <path d="M50 23.999C50 40.1493 28 60.999 28 60.999C28 60.999 6 40.1493 6 23.999C6 11.8488 15.8497 1.99902 28 1.99902C40.1503 1.99902 50 11.8488 50 23.999Z" fill="#E4002B"/>
            </g>
            <path d="M23.3759 31.731C22.7159 27.831 21.9659 23.451 21.3659 19.221C19.9859 24.501 18.9659 29.361 18.1859 34.851C17.9459 36.531 14.0759 34.581 14.3459 32.301C15.0659 26.211 17.1959 19.611 18.3059 15.531C18.5759 14.511 19.9259 14.781 20.8859 15.531L20.7659 14.361C20.5559 12.621 24.6959 13.791 25.0559 16.551C25.7159 21.831 26.2859 25.881 26.8859 29.241C29.1959 24.771 32.4659 19.131 35.1359 15.021C36.6059 12.771 41.6759 14.181 41.3159 16.761C40.8359 20.211 39.2759 28.221 38.0459 34.851C37.7159 36.591 34.2059 34.521 34.2059 32.301C34.2059 29.961 35.6459 25.131 36.5459 19.791C34.7159 22.671 32.9159 25.911 28.3559 34.581C27.4859 36.171 23.7359 34.041 23.3759 31.731Z" fill="white"/>
            <defs>
            <filter id="filter0_dd_903_3314" x="0" y="-0.000976562" width="56" height="71" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="3"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_903_3314"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>
            <feBlend mode="normal" in2="effect1_dropShadow_903_3314" result="effect2_dropShadow_903_3314"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_903_3314" result="shape"/>
            </filter>
            </defs>
          </svg>`
          break
        default:
          marker = 'Emtpy type'
          break
      }

      return marker
    },

    initTabActive() {
      const widgets = this.$el.querySelector('.widgets-categories')
      this.widgetsCategoriesSizeStore = widgets.offsetHeight
      widgets.style.height = '0px'
    },

    settingHandler() {
      this.isSettings = !this.isSettings
      if (this.isSettings) {
        // console.log(this.$refs);
        this.$refs.widgetsCategories.style.height =
          this.$refs.widgetWrap.offsetHeight + 'px'
        this.$refs.widgetsCategories.style.opacity = '1'
      } else {
        this.$refs.widgetsCategories.style.height = `0px`
        this.$refs.widgetsCategories.style.opacity = '0'
      }
    },

    regionHandler(e) {
      this.$refs.regionsRef.forEach((reg) => reg.classList.remove('active'))

      const target = e.target
      if (target.classList.contains('active')) {
        target.classList.remove('active')
      } else {
        target.classList.add('active')
      }

      this.searchQuery()
    },

    typeHandler(e, id) {
      const typeEl = this.$el.querySelector(
        `.search-settings__type[data-id="${id}"]`
      )
      typeEl.classList.toggle('active')

      this.searchQuery()
    },

    closeCardLocation() {
      this.isShowLocationCard = false
    },

    showCardLocation(id) {
      this.zoom = 13
      const currLocation = this.locations[this.$i18n.locale].filter((i) => {
        return i.id === id
      })

      const forCalculateLocation = {
        lat: currLocation[0].location.lat,
        lng: currLocation[0].location.lon
      }

      this.currentLocation = {
        lat: currLocation[0].location.lat,
        lng: currLocation[0].location.lon
      }

      this.locationCard = {
        id: currLocation[0].id,
        name: currLocation[0].name,
        imageUrl: currLocation[0].picture,
        type: currLocation[0].category.code,
        address: currLocation[0].address,
        point: currLocation[0].point,
        status: currLocation[0].status,
        isOpen: currLocation[0].is_open,
        openTo: currLocation[0].open_to,
        workTimeWeekdays: currLocation[0].work_time.weekdays,
        workTimeWeekend: currLocation[0].work_time.weekend,
        phone: currLocation[0].phone,
        services: []
      }

      this.isShowLocationCard = true

      this.getDistance(this.userLocation, forCalculateLocation)
    },

    closeWidgets() {
      this.isMobileShowWidgets = true

      this.bodyFixed = false
    },

    showWidgets() {
      this.isMobileShowWidgets = false

      this.bodyFixed = true
    },

    chooseCounter() {
      const types = this.$el.querySelectorAll(`.search-settings__type.active`)
      const regions = this.$el.querySelectorAll('.search-settings__city.active')
      const services = this.$el.querySelectorAll('.service-input:checked')

      this.chooseNumber =
        Number(types.length) + Number(services.length) + Number(regions.length)
    },

    // Filter
    async searchQuery(param) {
      // Query string
      const q = this.searchQueryStr

      // Types
      const queryTypes = this.queryStrGenerator('type', 'category', true)

      // Services
      const queryServices = this.queryStrGenerator('service', 'service', false)

      // Regions
      const queryRegions = this.queryStrGenerator('city', 'region', true)

      this.$router.push({
        path: this.$route.path,
        query: {
          q,
          'category[]': queryTypes.ids,
          'service[]': queryServices.ids,
          'region[]': queryRegions.ids
        }
      })
      // Query
      const { data } = await this.$axios.$get(
        `${process.env.API_URL_SITE}/shop_search/?q=${q}&${queryTypes.string}&${queryServices.string}&${queryRegions.string}`
      )

      this.$i18n.localeCodes.forEach((locale) => {
        this.regionSeoBlock[locale].route =
          data.items?.[locale]?.[0]?.region?.route
        this.regionSeoBlock[locale].h1 = data.items?.[locale]?.[0]?.region?.h1
        this.regionSeoBlock[locale].title =
          data.items?.[locale]?.[0]?.region?.title
        this.regionSeoBlock[locale].text =
          data.items?.[locale]?.[0]?.region?.text
      })

      // console.log(this.regionSeoBlock.ru.route.replace(/\/stores\//g, ""));
      // history.pushState(null, null, this.regionSeoBlock.ru.route)
      if (!data.items[0] || q.length) {
        this.locations = data.items
      } else {
        this.originLocations = data.items
      }

      this.chooseCounter()
    },

    queryStrGenerator(type, queryStr, isBoolean) {
      if (isBoolean) {
        const types = this.$el.querySelectorAll(`.search-settings__${type}`)

        let queryTypesStr = ''
        const arr = []

        types.forEach((i) => {
          if (i.classList.contains('active')) {
            queryTypesStr += `${queryStr}[]=${i.getAttribute('data-id')}&`
            arr.push(i.getAttribute('data-id'))
          }
        })

        return {
          string: queryTypesStr.substring(0, queryTypesStr.length - 1),
          ids: arr
        }
      } else {
        const types = this.$el.querySelectorAll(`.search-settings__${type}`)

        let queryTypesStr = ''
        const arr = []

        types.forEach((i) => {
          const curr = i.querySelector(`.${type}-input`)

          if (curr.checked) {
            queryTypesStr += `${queryStr}[]=${i.getAttribute('data-id')}&`
            arr.push(i.getAttribute('data-id'))
          }
        })

        return {
          string: queryTypesStr.substring(0, queryTypesStr.length - 1),
          ids: arr
        }
      }
    },

    getCurrentLocation() {
      this.zoom = 14
      navigator.geolocation.getCurrentPosition((pos) => {
        this.userLocation.lat = pos.coords.latitude || 0
        this.userLocation.lng = pos.coords.longitude || 0

        this.currentLocation.lat = pos.coords.latitude || 0
        this.currentLocation.lng = pos.coords.longitude || 0
      })

      this.clickedCurrentLocationButton = true

      this.closeCardLocation()
    },

    zoomPlus() {
      this.$refs.map.zoom = this.$refs.map.$mapObject.zoom + 1
    },

    zoomMinus() {
      this.$refs.map.zoom = this.$refs.map.$mapObject.zoom - 1
    },

    cleanSearchQuery() {
      this.searchQueryStr = ''
    },

    rad(x) {
      return (x * Math.PI) / 180
    },

    getDistance(p1, p2) {
      const R = 6378137
      const dLat = this.rad(p2.lat - p1.lat)
      const dLong = this.rad(p2.lng - p1.lng)
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.rad(p1.lat)) *
          Math.cos(this.rad(p2.lat)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const d = R * c

      if (Math.round(d) >= 1000) {
        this.fromYou = `${Math.floor(Math.round(d) / 1000)} км от вас`
      } else {
        this.fromYou = `${Math.floor(Math.round(d))} метров от вас`
      }
    }
  }
}
