
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    beforeEnter(el) {
      this.$nextTick(() => {
        el.style.height = "0"
      })
    },
    enter(el) {
      this.$nextTick(() => {
        el.style.height = el.scrollHeight + "px"
      })
    },
    beforeLeave(el) {
      this.$nextTick(() => {
        el.style.height = el.scrollHeight + "px"
      })
    },
    leave(el) {
      this.$nextTick(() => {
        el.style.height = "0"
      })
    },
    requisitesToggler() {
      this.$emit("toggle")
      if (!this.$refs.requisites.classList.contains("active")) {
        this.$refs.requisites.classList.add("active")

        this.$refs.content.style.height = `${this.h}px`
      } else {
        this.$refs.requisites.classList.remove("active")
        this.$refs.content.style.height = `0px`
      }
    }
  }
}
