
import circle from '~/static/lottiefiles/circle.json'
export default {
  name: 'AboutHistoryLayout',

  data: (vm) => ({
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      infinite: false,
      draggable: false,
      variableWidth: true
    },

    histories: [],

    prevIconLottiefile: null,
    nextIconLottiefile: null
  }),

  async fetch() {
    const { data } = await this.$axios.$get(
      `${process.env.API_URL_SITE}/history/list`
    )

    this.histories = data.items
  },

  mounted() {
    this.initLottieFiles()
  },

  methods: {
    initLottieFiles() {
      this.prevIconLottiefile = window.lottie.loadAnimation({
        container: this.$refs.prev,
        loop: false,
        autoplay: false,
        animationData: circle
      })

      this.nextIconLottiefile = window.lottie.loadAnimation({
        container: this.$refs.next,
        loop: false,
        autoplay: false,
        animationData: circle
      })
    },

    slideToNext() {
      this.$refs.vsc.next()
    },

    slideToPrev() {
      this.$refs.vsc.prev()
    },

    // Lottie files
    startPrevIconLottiefile() {
      this.prevIconLottiefile.setDirection(1)
      this.prevIconLottiefile.play()
    },

    endPrevIconLottiefile() {
      this.prevIconLottiefile.setDirection(-1)
      this.prevIconLottiefile.play()
    },

    startNextIconLottiefile() {
      this.nextIconLottiefile.setDirection(1)
      this.nextIconLottiefile.play()
    },

    endNextIconLottiefile() {
      this.nextIconLottiefile.setDirection(-1)
      this.nextIconLottiefile.play()
    }
  }
}
