
import animeData from '~/static/lottiefiles/circle.json'
export default {
  name: 'AboutTestimonialsLayout',

  filters: {
    initialGenerator(str) {
      const letters = str.split('')

      const fI = []
      const lI = []

      let isSpaceLetter = false
      letters.map((l) => {
        if (!isSpaceLetter) {
          fI.push(l)
        }
        if (l === ' ') isSpaceLetter = true
        lI.push(l)
        return true
      })
      const lI2 = lI.join('').slice(fI.length).split('')
      return `${fI[0]}${lI2[0]}`
    }
  },

  data: (vm) => ({
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      infinite: false,
      draggable: false,
      variableWidth: true
    },
    testimonials: [],

    prevIconLottiefile: null,
    nextIconLottiefile: null
  }),

  async fetch() {
    const { data } = await this.$axios.$get(
      `${process.env.API_URL_SITE}/comments/list`
    )

    this.testimonials = data.items
  },

  mounted() {
    this.initLottieFiles()
  },

  methods: {
    initLottieFiles() {
      this.prevIconLottiefile = window.lottie.loadAnimation({
        container: this.$refs.prev,
        loop: false,
        autoplay: false,
        animationData: animeData
      })

      this.nextIconLottiefile = window.lottie.loadAnimation({
        container: this.$refs.next,
        loop: false,
        autoplay: false,
        animationData: animeData
      })
    },

    slideToNext() {
      this.$refs.vsc.next()
    },

    slideToPrev() {
      this.$refs.vsc.prev()
    },

    // Lottie files
    startPrevIconLottiefile() {
      this.prevIconLottiefile.setDirection(1)
      this.prevIconLottiefile.play()
    },

    endPrevIconLottiefile() {
      this.prevIconLottiefile.setDirection(-1)
      this.prevIconLottiefile.play()
    },

    startNextIconLottiefile() {
      this.nextIconLottiefile.setDirection(1)
      this.nextIconLottiefile.play()
    },

    endNextIconLottiefile() {
      this.nextIconLottiefile.setDirection(-1)
      this.nextIconLottiefile.play()
    }
  }
}
