
export default {
  name: 'SpecialLayoutThird',

  props: {
    config: { type: Object, required: true, default: () => {} }
  },

  methods: {
    setURIFunc(type, id) {
      let uri = ''

      switch (type) {
        case false:
          uri = `catalog/special?id=${id}`
          break
        case true:
          uri = `catalog/static/${id}`
          break
        default:
          uri = `catalog/special?id=${id}`
          break
      }
      return uri
    },

    mq(fz) {
      if(process.client) {
        const w = window?.innerWidth
        const removeFromFzPercent = Number(fz) * 0.4
  
        if (w >= 1200) {
          return `${fz}px`
        } else {
          return `${fz - removeFromFzPercent}px`
        }
      }
    }
  }
}
