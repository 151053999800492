
export default {
  name: 'BaseProduct',
  filters: {
    truncate(str) {
      return str.length < 30
        ? str
        : `${str.substr(
            0,
            str.substr(0, 30 - '...'.length).lastIndexOf(' ')
          )}...`
    }
  },

  props: {
    productItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      promotion_tags: this.productItem.promotion_tags,
      novelty: 1,
      smart_action: 2,
      discount_price: 3,
      super_price: 4,
      increased_cashback: 5,
      selfmade: 6,
      discount: 7
    }
  },

  computed: {
    tags() {
      return this.promotion_tags.length
        ? this.promotion_tags.filter(
            (el) => el.id !== this.discount_price && el.id !== this.super_price
          )
        : []
      // return []
    },
    sortedTags() {
      // Определяем порядок тегов на основе их идентификаторов
      return this.tags.sort((a, b) => {
        if (a.id === this.selfmade) return -1 // selfmade первым
        if (a.id === this.smart_action) return 1 // smart_action вторым
        if (a.id === this.increased_cashback && b.id !== this.selfmade) return 1 // increased_cashback следующим после selfmade
        if (
          a.id === this.novelty &&
          b.id !== this.selfmade &&
          b.id !== this.increased_cashback
        )
          return 1 // novelty следующим после increased_cashback
        return 0 // все остальные остаются на своих местах
      })
    },

    title() {
      const title = this.productItem[`title_${this.$i18n.locale}`]
        ? this.productItem[`title_${this.$i18n.locale}`]
        : this.productItem.title
      return title
    },
    isOldCardType() {
      return !this.promotion_tags.length
    }
  }
}
