
import circle from '~/static/lottiefiles/circle.json'
export default {
  name: 'UseBrandPage',

  async asyncData({ app, env, $axios }) {
    const response = await $axios.$post(`${env.API_URL_SITE}/seo`, {
      url: '/logotip',
      project: "korzinka.uz"
    })

    const currentLocale = app.i18n.locale;

    return { seo: response?.data?.items || null, faq: response?.data?.items?.[currentLocale]?.[0]?.QNA || null }
  },

  data: (vm) => ({
    seo: null,
    faq: null,
    settings: {
      slidesToShow: 1,
      slideToScroll: 1,
      arrows: false,
      dots: false,
      infinite: true,
      draggable: false
    },

    content: {},

    isBrowserMobi: false,

    prevIconLottiefile: null,
    nextIconLottiefile: null,

    prevIconLottiefile2: null,
    nextIconLottiefile2: null,

    prevIconBlockLottiefile: null,
    nextIconBlockLottiefile: null
  }),

  head() {
    return {
      title: this.seo[this.$i18n.locale][0].title,
      meta: [
        { hid: 'og:type', property: 'og:type', content: 'website' },
        {
          hid: 'description',
          name: 'description',
          content: this.seo[this.$i18n.locale][0].description
        },

        {
          hid: 'og-title',
          property: 'og:title',
          content: this.seo[this.$i18n.locale][0].og_title
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content: this.seo[this.$i18n.locale][0].og_description
        },
        {
          hid: 'og-url',
          property: 'og:url',
          content: this.seo[this.$i18n.locale][0].og_url
        },
        {
          hid: 'og-image',
          property: 'og:image',
          content: this.seo[this.$i18n.locale][0].og_img
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image'
        }
      ],

      bodyAttrs: {
        class: this.bodyFixed ? 'fixed' : ''
      },

      link: [
        {
          rel: 'canonical',
          // href: `https://korzinka.uz/${
          //   this.$i18n.locale === 'ru' ? '' : this.$i18n.locale + '/'
          // }use-brand`
          href: `https://korzinka.uz/${
            this.$i18n.locale === 'ru' ? '' : this.$i18n.locale + '/'
          }logotip`
        },

        {
          rel: 'alternate',
          href: `https://korzinka.uz/logotip`,
          hreflang: 'ru'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/uz/logotip`,
          hreflang: 'uz'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/en/logotip`,
          hreflang: 'en'
        }
      ]
    }
  },

  mounted() {
    this.fetchDatas()
    // try {
    //   const { items } = await fetchMetaTags('/use-brand')
    //   this.seo = items[this.$i18n.locale][0]
    // } catch (error) {
    //   console.log(error)
    // }

    const browserWidth = window.innerWidth

    browserWidth < 700
      ? (this.isBrowserMobi = true)
      : (this.isBrowserMobi = false)

    this.initLottieFiles()
  },

  methods: {
    async fetchDatas() {
      const response = await this.$axios.$get(
        `${process.env.API_URL_SITE}/use_materials`
      )

      this.content = response
    },
    slideToNext() {
      this.$refs.vsc.next()
    },
    slideToPrev() {
      this.$refs.vsc.prev()
    },

    slideToNext2() {
      this.$refs.vsc2.next()
    },
    slideToPrev2() {
      this.$refs.vsc2.prev()
    },

    slideToNextBlock() {
      this.$refs.vscblock.next()
    },
    slideToPrevBlock() {
      this.$refs.vscblock.prev()
    },

    initLottieFiles() {
      this.prevIconLottiefile = window.lottie.loadAnimation({
        container: this.$refs.prev,
        loop: false,
        autoplay: false,
        animationData: circle
      })

      this.nextIconLottiefile = window.lottie.loadAnimation({
        container: this.$refs.next,
        loop: false,
        autoplay: false,
        animationData: circle
      })

      this.prevIconLottiefile2 = window.lottie.loadAnimation({
        container: this.$refs.prev2,
        loop: false,
        autoplay: false,
        animationData: circle
      })

      this.nextIconLottiefile2 = window.lottie.loadAnimation({
        container: this.$refs.next2,
        loop: false,
        autoplay: false,
        animationData: circle
      })

      this.prevIconBlockLottiefile = window.lottie.loadAnimation({
        container: this.$refs.prevblock,
        loop: false,
        autoplay: false,
        animationData: circle
      })
      this.nextIconBlockLottiefile = window.lottie.loadAnimation({
        container: this.$refs.nextblock,
        loop: false,
        autoplay: false,
        animationData: circle
      })
    },

    // Lottie files
    startPrevIconLottiefile() {
      this.prevIconLottiefile.setDirection(1)
      this.prevIconLottiefile.play()
    },

    endPrevIconLottiefile() {
      this.prevIconLottiefile.setDirection(-1)
      this.prevIconLottiefile.play()
    },

    startNextIconLottiefile() {
      this.nextIconLottiefile.setDirection(1)
      this.nextIconLottiefile.play()
    },

    endNextIconLottiefile() {
      this.nextIconLottiefile.setDirection(-1)
      this.nextIconLottiefile.play()
    },

    startPrevIconLottiefile2() {
      this.prevIconLottiefile2.setDirection(1)
      this.prevIconLottiefile2.play()
    },

    endPrevIconLottiefile2() {
      this.prevIconLottiefile2.setDirection(-1)
      this.prevIconLottiefile2.play()
    },

    startNextIconLottiefile2() {
      this.nextIconLottiefile2.setDirection(1)
      this.nextIconLottiefile2.play()
    },

    endNextIconLottiefile2() {
      this.nextIconLottiefile2.setDirection(-1)
      this.nextIconLottiefile2.play()
    },

    startPrevIconBlockLottiefile() {
      this.prevIconBlockLottiefile.setDirection(1)
      this.prevIconBlockLottiefile.play()
    },

    endPrevIconBlockLottiefile() {
      this.prevIconBlockLottiefile.setDirection(-1)
      this.prevIconBlockLottiefile.play()
    },

    startNextIconBlockLottiefile() {
      this.nextIconBlockLottiefile.setDirection(1)
      this.nextIconBlockLottiefile.play()
    },

    endNextIconBlockLottiefile() {
      this.nextIconBlockLottiefile.setDirection(-1)
      this.nextIconBlockLottiefile.play()
    }
  }
}
