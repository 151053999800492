
export default {
  name: 'RegionSeoBlock',

  props: {
    regionSeoData: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },

  data: () => ({})
}
