import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=b62912d0"
import script from "./Hero.vue?vue&type=script&lang=js"
export * from "./Hero.vue?vue&type=script&lang=js"
import style0 from "./Hero.vue?vue&type=style&index=0&id=b62912d0&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainBannersStandartBanner: require('/home/korzinka/www/front/components/MainBanners/StandartBanner.vue').default,MainBannersStandartWithoutEllipseBanner: require('/home/korzinka/www/front/components/MainBanners/StandartWithoutEllipseBanner.vue').default,MainBannersBigBanner: require('/home/korzinka/www/front/components/MainBanners/BigBanner.vue').default})
