
export default {
  name: 'QrPage',

  data: () => ({
    isCheckThisPage: false
  }),

  mounted() {
    this.customizeForDevice()

    setTimeout(() => {
      window.location.href = 'https://korzinka.uz'
    }, 1000)
  },

  methods: {
    customizeForDevice() {
      const ua = navigator.userAgent
      const checker = {
        iphone: ua.match(/(iPhone|iPod|iPad)/),
        android: ua.match(/Android/)
      }
      if (checker.iphone) {
        window.location.href = `${process.env.APPSTORE_URI}`
      } else if (checker.android) {
        window.location.href = `${process.env.GOOGLEPLAY_URI}`
      } else {
        window.location.href = `https://korzinka.uz/app/${this.$i18n.locale}`
      }
    }
  }
}
