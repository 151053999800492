import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=4a96af35&scoped=true"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"
import style0 from "./_id.vue?vue&type=style&index=0&id=4a96af35&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a96af35",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheShare: require('/home/korzinka/www/front/components/TheShare.vue').default,TheAccordion: require('/home/korzinka/www/front/components/TheAccordion.vue').default,SeoBlock: require('/home/korzinka/www/front/components/SeoBlock.vue').default})
