
export default {
  name: 'BaseBigBanner',

  props: {
    banner: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
}
