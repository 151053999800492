
import animeData from '~/static/lottiefiles/hero.json'
import arrowDanger from '~/static/lottiefiles/arrow-danger.json'

export default {
  name: 'HeroLayout',

  props: {
    banners: {
      type: null,
      default: null,
      required: true
    }
  },

  data() {
    return {
      fileLottieBYD: null,

      settingsForSlides: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        draggable: false,
        fade: true,
        speed: 300,
        asNavFor: this.$refs.slidesNav
      },

      settingsNavSlides: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        draggable: false,
        infinite: true,
        centerMode: true,
        autoplay: true,
        autoplaySpeed: 5100,
        focusOnSelect: true,
        asNavFor: this.$refs.slidesFor,
        speed: 300,

        responsive: [
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              pauseOnFocus: false,
              pauseOnHover: false
            }
          }
        ]
      },

      classForBody: '',
      heroLottie: null,
      slides: [],
      bannersText: [],
      ellipses: [],
      slideNavs: [],

      types: {
        row: 'image'
      },

      isLoadingBanner: false
    }
  },

  head() {
    return {
      bodyAttrs: {
        class: this.classForBody
      }
    }
  },

  mounted() {
    this.slides = this.$el.querySelectorAll('.hero__slides-for .slick-slide')
    this.bannersText = this.$el.querySelectorAll('.hero__text_banner')
    this.ellipses = this.$el.querySelectorAll('.ellipse-animation')
    this.slideNavs = this.$el.querySelectorAll('.hero__slide-nav')

    setTimeout(() => {
      this.defaultConfigSlides()

      this.classForBody = 'curr-banner'
    }, 0)

    this.isLoadingBanner = true
    this.lottieFiles()
    this.lottieFile()
    this.heroLottie.play()
  },

  methods: {
    detectBrowserWidth() {
      if (process.client) {
        if (window.innerWidth > 500) {
          return true
        } else {
          return false
        }
      }
    },

    defaultConfigSlides() {
      const currMiniSlide = this.$el.querySelector(
        '.hero__slides-nav .slick-active.slick-center'
      )

      this.slideNavs.forEach((h) => h.classList.remove('active'))

      if (currMiniSlide) {
        const slideNav = currMiniSlide.querySelector('.hero__slide-nav')

        slideNav.classList.add('active')
      }

      this.bannersText.forEach((b) => b.classList.add('active'))

      const currEllipse = this.$el.querySelector('.ellipse-animation')

      if (currEllipse) {
        currEllipse.classList.add('no-skew')
        currEllipse.classList.add('no-clipping')
        currEllipse.classList.add('unset-clipping')
      }
    },

    initVueSlickCarousel() {
      this.heroLottie.play()
    },

    lottieFiles() {
      this.heroLottie = window.lottie.loadAnimation({
        name: 'heroData',
        container: this.$refs.heroData,
        loop: false,
        autoplay: false,
        animationData: animeData
      })
    },

    productAnimation(gsap, curr, next, nextSlide, bannerTexts, ellipses) {
      if (curr) {
        gsap.fromTo(
          curr,
          0.3,
          {
            css: { transform: 'scale(1)' }
          },
          {
            css: { transform: 'scale(0)' }
          }
        )
      }

      bannerTexts.forEach((b) => b.classList.remove('active'))
      ellipses.forEach((e) => e.classList.remove('no-skew'))
      ellipses.forEach((e) => e.classList.remove('no-clipping'))
      ellipses.forEach((e) => e.classList.remove('unset-clipping'))

      this.$refs.heroData.classList.add('disabled')

      gsap.delayedCall(0.2, () => {
        this.heroLottie.stop()
      })

      gsap.delayedCall(0.3, () => {
        this.classForBody = ''

        gsap.fromTo(
          next,
          0.3,
          {
            css: { transform: 'scale(0)' }
          },
          {
            css: { transform: 'scale(1)' }
          }
        )

        this.$refs.heroData.classList.remove('disabled')

        this.heroLottie.play()
        this.$refs.slidesFor.goTo(nextSlide)
      })
    },
    bannerAnimation(
      gsap,
      type,
      currEllipse,
      nextSlide,
      curr,
      ellipses,
      bannerTexts
    ) {
      if (curr) {
        gsap.fromTo(
          curr,
          0.3,
          {
            css: { transform: 'scale(1)' }
          },
          {
            css: { transform: 'scale(0)' }
          }
        )
      }

      ellipses.forEach((e) => e.classList.remove('no-skew'))
      ellipses.forEach((e) => e.classList.remove('no-clipping'))
      ellipses.forEach((e) => e.classList.remove('unset-clipping'))
      bannerTexts.forEach((b) => b.classList.remove('active'))

      this.$refs.heroData.classList.add('disabled')

      gsap.delayedCall(0.2, () => {
        this.heroLottie.stop()
      })

      gsap.delayedCall(0.3, () => {
        this.classForBody = ''
        if (type === 'with-gradient') {
          this.classForBody = 'curr-banner'
        }

        gsap.delayedCall(0.1, () => {
          bannerTexts.forEach((b) => b.classList.add('active'))
        })

        if (currEllipse) currEllipse.classList.add('no-skew')

        gsap.delayedCall(0.2, () => {
          currEllipse.classList.add('no-clipping')
          currEllipse.classList.add('unset-clipping')
        })

        this.$refs.heroData.classList.remove('disabled')

        this.heroLottie.play()
        this.$refs.slidesFor.goTo(nextSlide)
      })
    },

    navAnimation(nextSlide) {
      const currMiniSlide = this.$el.querySelector(
        '.hero__slides-nav .slick-active.slick-center'
      )
      this.slideNavs.forEach((h) => h.classList.remove('active'))
      const slideNav = currMiniSlide.querySelector('.hero__slide-nav')
      slideNav.classList.add('active')

      this.heroLottie.play()
      this.$refs.slidesFor.goTo(nextSlide)
    },

    onBeforeChangeFor(currentSlide, nextSlide) {
      const gsap = window.gsap
      const curr = this.slides[currentSlide].querySelector('.hero__product')
      const next = this.slides[nextSlide].querySelector('.hero__product')
      const currSlide = this.slides[nextSlide].querySelector('.hero__slide-for')
      const type = currSlide.getAttribute('data-type')
      const currEllipse = currSlide.querySelector('.ellipse-animation')

      if (type === 'product') {
        this.productAnimation(
          gsap,
          curr,
          next,
          nextSlide,
          this.bannersText,
          this.ellipses
        )
      } else {
        this.bannerAnimation(
          gsap,
          type,
          currEllipse,
          nextSlide,
          curr,
          this.ellipses,
          this.bannersText
        )
      }
    },

    onBeforeChangeNav(_, nextSlide) {
      this.navAnimation(nextSlide)
    },

    mq(fz) {
      if (process.client) {
        const removeFromFzPercent = Number(fz) * 0.4
        if (window.innerWidth >= 500) {
          return `${fz}px`
        } else {
          return `${fz - removeFromFzPercent}px`
        }
      }
    },

    lottieFile() {
      this.fileLottieBYD = window.lottie.loadAnimation({
        name: 'id-byd',
        container: this.$el.querySelector(`.l-byd`),
        loop: false,
        autoplay: false,
        animationData: arrowDanger
      })
    },

    startArrowLottieFile() {
      this.fileLottieBYD.setDirection(1)
      this.fileLottieBYD.setSpeed(3)
      this.fileLottieBYD.play()
    },

    endArrowLottieFile() {
      this.fileLottieBYD.setDirection(-1)
      this.fileLottieBYD.setSpeed(3)
      this.fileLottieBYD.play()
    }
  }
}
