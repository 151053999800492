
import arrowDanger from '~/static/lottiefiles/arrow-danger.json'
import arrowLight from '~/static/lottiefiles/arrow-light.json'
export default {
  name: 'BigBanner',

  props: {
    config: {
      type: Object,
      required: true,
      default: () => {}
    },
    lazyLoad: {
      type: Boolean,
      default: true, // Default value if not provided
    },
  },

  data() {
    return {
      fileLottie: null
    }
  },

  mounted() {
    this.lottieFile(this.config.nav_color, this.config.id)

    this.detectBrowserWidth
  },

  methods: {
    detectBrowserWidth() {
      if (process.client) {
        if (window.innerWidth > 500) {
          return true
        } else {
          return false
        }
      }
    },

    mq(desc, mob) {
      const removeFromFzPercent = Number(desc) * 0.4
      if (desc && !mob) {
        if (window.innerWidth >= 500) {
          return `${desc}px`
        } else {
          return `${desc - removeFromFzPercent}px`
        }
      } else {
        if (window.innerWidth >= 500) {
          return `${desc}px`
        } else {
          return `${mob}px`
        }
      }
    },
    titleStyles() {
      if (this.detectBrowserWidth) {
        return {
          fontSize: this.mq(this.config.title_size, this.config.mob_title_size),
          lineHeight: this.mq(
            this.config.title_size,
            this.config.mob_title_size
          ),
          color: this.config.title_hex,
          '--dynamic-bg-color':
            this.config.active_plashka === ''
              ? '#e4002b'
              : this.config.active_plashka === 'Y'
              ? this.config.color_plashka
              : '',
          padding: this.config.active_plashka === 'N' ? '0px' : ''
        }
      } else {
        return {
          fontSize: `${
            this.config.mob_titleFigure_size
              ? this.config.mob_titleFigure_size
              : this.config.mob_title_size
          }px`,
          lineHeight: `${
            this.config.mob_titleFigure_size
              ? this.config.mob_titleFigure_size
              : this.config.mob_title_size
          }px`,
          color: this.config.title_hex,
          '--dynamic-bg-color':
            this.config.active_plashka === ''
              ? '#e4002b'
              : this.config.active_plashka === 'Y'
              ? this.config.color_plashka
              : '',
          padding: this.config.active_plashka === 'N' ? '0px' : ''
        }
      }
    },
    navigate() {
      if (this.config.link.url !== '') {
        window.location.href = this.config.link.url
      }
    },
    // mq(fz) {
    //   if(process.client) {
    //     const removeFromFzPercent = Number(fz) * 0.4
    //     if (window.innerWidth >= 500) {
    //       return `${fz}px`
    //     } else {
    //       return `${fz - removeFromFzPercent}px`
    //     }
    //   }
    // },
    // mq(fz) {
    //   if(process.client) {
    //     const removeFromFzPercent = Number(fz) * 0.4
    //     if (window.innerWidth >= 500) {
    //       return `${fz}px`
    //     } else {
    //       return `${fz - removeFromFzPercent}px`
    //     }
    //   }
    // },

    lottieFile(color, id) {
      this.fileLottie = window.lottie.loadAnimation({
        name: `id-${id}`,
        container: this.$el.querySelector(`.l${id}`),
        loop: false,
        autoplay: false,
        animationData: color === 'red' ? arrowDanger : arrowLight
      })
    },

    startArrowLottieFile() {
      this.fileLottie.setDirection(1)
      this.fileLottie.setSpeed(3)
      this.fileLottie.play()
    },

    endArrowLottieFile() {
      this.fileLottie.setDirection(-1)
      this.fileLottie.setSpeed(3)
      this.fileLottie.play()
    }
  }
}
