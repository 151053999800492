
export default {
  name: 'CharityLayout',

  data() {
    return {
      sum: null
    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      const response = await this.$axios.$get(
        `${process.env.API_URL_SITE}/charity_sum`
      )

      this.sum = response.data
    }
  }
}
