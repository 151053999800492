
export default {
  name: 'BaseEmptyResult',

  props: {
    products: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  data: (vm) => ({}),

  mounted() {},

  methods: {}
}
