
export default {
  name: 'ProductPage',

  head() {
    return {
      title: `Korzinka | ${this.$t('catalog')}`
    }
  },

  created() {
    const localeUrl =
      this.$i18n.locale !== 'ru' ? `/${this.$i18n.locale}/` : '/'
    this.$router.push(`${localeUrl}catalog?category=${this.$route.params.id}`)
  }

  // created() {
  //   this.$router.push(`/catalog?category=${this.$route.params.id}`)
  // }
}
