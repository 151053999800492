
export default {
  name: 'ErrorLayout',

  data: (vm) => ({
    products: [],

    errorNum: 1,
    figure: ''
  }),
  head() {
    return {
      meta: [
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow'
        }
      ]
    }
  },
  mounted() {
    this.fetchDatas()
    this.errorNum = Math.floor(Math.random() * 4)
    if (this.errorNum === 0) this.errorNum++

    switch (this.errorNum) {
      case 1:
        this.figure = 'watermelon'
        break
      case 2:
        this.figure = 'banana'
        break
      case 3:
        this.figure = 'milk'
        break
      default:
        this.figure = 'banana'
        break
    }
  },

  methods: {
    async fetchDatas() {
      try {
        const { data } = await this.$axios.$get('/mobile/catalogs-list')
        const id = data[0].categories[0].get_products
        const response = await this.$axios.$post(
          '/mobile/catalogs/category/products',
          {
            get_products: id
          }
        )
        this.products = response.data.splice(0, 5)
      } catch (e) {}
    }
  }
}
