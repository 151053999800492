
export default {
  name: 'WorkLayout',

  data: () => ({
    suppliers: {}
  }),

  mounted() {
    this.fetchDatas()
  },

  methods: {
    async fetchDatas() {
      const response = await this.$axios.$get(
        `${process.env.API_URL_SITE}/for_suppliers/`
      )

      this.suppliers = response.data
    }
  }
}
