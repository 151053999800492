import { render, staticRenderFns } from "./Builder.vue?vue&type=template&id=7759531b"
import script from "./Builder.vue?vue&type=script&lang=js"
export * from "./Builder.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PostStandard: require('/home/korzinka/www/front/components/Post/Standard.vue').default,PostMiddle: require('/home/korzinka/www/front/components/Post/Middle.vue').default,PostLarge: require('/home/korzinka/www/front/components/Post/Large.vue').default})
