
export default {
  name: 'AboutStaffLayout',

  filters: {
    getOnlyName(fullname) {
      return fullname.substring(0, fullname.indexOf(' '))
    }
  },

  data: (vm) => ({
    staffSettings: {
      slidesToShow: 1,
      infinite: true,
      arrows: false,
      dots: false,
      draggable: false
      // responsive: [
      //   {
      //     breakpoint: 1200,
      //     settings: {
      //       draggable: true
      //     }
      //   }
      // ]
    },

    staffTopSettings: {
      speed: 20000,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: 'linear',
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      arrows: false,
      dots: false,
      rtl: true,
      pauseOnHover: false
    },

    staffBotSettings: {
      speed: 20000,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: 'linear',
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      arrows: false,
      dots: false,
      pauseOnHover: false
    },

    mainSlides: [],
    botSlides: [],
    staffTabs: [],

    all: {
      ru: [],
      en: [],
      uz: []
    },

    supervisory: {
      ru: [],
      en: [],
      uz: []
    },

    managers: {
      ru: [],
      en: [],
      uz: []
    }
  }),

  async fetch() {
    const { data } = await this.$axios.$get(
      `${process.env.API_URL_SITE}/employees/list`
    )
    const res = await this.$axios.$get(
      `${process.env.API_URL_SITE}/employees-cat/list`
    )

    this.staffTabs = res.data
    this.mainSlides = data.items
    this.botSlides = data.items
  },

  mounted() {
    setTimeout(() => {
      this.filterEmployers()
    }, 1000)
  },

  methods: {
    staffTab(id, curr) {
      const tabs = document.querySelectorAll('.about-page__tab')
      const wrappers = document.querySelectorAll('.about-page__staff-wrapper')

      tabs.forEach((i) => i.classList.remove('active'))

      wrappers.forEach((i) => i.classList.remove('active'))

      curr.currentTarget.classList.add('active')

      const currId = document.getElementById(id)

      currId.classList.add('active')
    },

    staffSliderToPrev() {
      this.$refs.staffSlider.prev()
    },
    staffSliderToNext() {
      this.$refs.staffSlider.next()
    },

    staffSliderToPrev2() {
      this.$refs.staffSlider2.prev()
    },
    staffSliderToNext2() {
      this.$refs.staffSlider2.next()
    },

    staffSliderToPrev3() {
      this.$refs.staffSlider3.prev()
    },
    staffSliderToNext3() {
      this.$refs.staffSlider3.next()
    },

    filterEmployers() {
      this.all = this.mainSlides

      this.mainSlides.ru.forEach((i) => {
        if (i.category_id === '17') {
          this.supervisory.ru.push(i)
        }
      })
      this.mainSlides.en.forEach((i) => {
        if (i.category_id === '17') {
          this.supervisory.en.push(i)
        }
      })
      this.mainSlides.uz.forEach((i) => {
        if (i.category_id === '17') {
          this.supervisory.uz.push(i)
        }
      })

      this.mainSlides.ru.forEach((i) => {
        if (i.category_id === '18') {
          this.managers.ru.push(i)
        }
      })
      this.mainSlides.en.forEach((i) => {
        if (i.category_id === '18') {
          this.managers.en.push(i)
        }
      })
      this.mainSlides.uz.forEach((i) => {
        if (i.category_id === '18') {
          this.managers.uz.push(i)
        }
      })
    }
  }
}
