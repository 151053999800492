import { render, staticRenderFns } from "./Sales.vue?vue&type=template&id=2588814e&scoped=true"
import script from "./Sales.vue?vue&type=script&lang=js"
export * from "./Sales.vue?vue&type=script&lang=js"
import style0 from "./Sales.vue?vue&type=style&index=0&id=2588814e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2588814e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SalesLayoutFirst: require('/home/korzinka/www/front/components/Sales/LayoutFirst.vue').default,SalesLayoutSecond: require('/home/korzinka/www/front/components/Sales/LayoutSecond.vue').default,SalesLayoutThird: require('/home/korzinka/www/front/components/Sales/LayoutThird.vue').default,SalesLayoutFourth: require('/home/korzinka/www/front/components/Sales/LayoutFourth.vue').default,SalesLayoutFive: require('/home/korzinka/www/front/components/Sales/LayoutFive.vue').default})
