
  export default {
    props: {
      coupon: {
        type: Object,
        default: null
      }
    },
    computed: {
      formatDate() {
        return String(this.coupon.date).split(' ');
      },
      modifiedDate() {
        return String(this.formatDate[0]).split('-');
      },
      date() {
        return `${this.modifiedDate[2]}.${this.modifiedDate[1]}.${this.modifiedDate[0]}`;
      },
      time() {
        return this.formatDate[1];
      }
    }
  }
