import { render, staticRenderFns } from "./index.vue?vue&type=template&id=f0b9ff08&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=f0b9ff08&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0b9ff08",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SpecialLayoutFirst: require('/home/korzinka/www/front/components/Special/LayoutFirst.vue').default,SpecialLayoutSecond: require('/home/korzinka/www/front/components/Special/LayoutSecond.vue').default,SpecialLayoutThird: require('/home/korzinka/www/front/components/Special/LayoutThird.vue').default,SpecialLayoutFourth: require('/home/korzinka/www/front/components/Special/LayoutFourth.vue').default,SpecialLayoutFive: require('/home/korzinka/www/front/components/Special/LayoutFive.vue').default,BaseBigBanner: require('/home/korzinka/www/front/components/base/BigBanner.vue').default,BaseCustomBanner: require('/home/korzinka/www/front/components/base/CustomBanner.vue').default,BaseProduct: require('/home/korzinka/www/front/components/base/Product.vue').default,TheAccordion: require('/home/korzinka/www/front/components/TheAccordion.vue').default,SeoBlock: require('/home/korzinka/www/front/components/SeoBlock.vue').default})
