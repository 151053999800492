
export default {
  name: 'IndexPage',

  async asyncData({ app, env, $axios }) {
    const currentLocale = app.i18n.locale
    let banners
    let seo
    let faq
    try {
      const bannersResponse = await $axios.$get(
        `${env.API_URL_SITE}/banners/list`
      )

      banners = bannersResponse?.data?.items
    } catch (e) {}

    try {
      const seoResponse = await $axios.$post(`${env.API_URL_SITE}/seo`, {
        url: '/',
        project: 'korzinka.uz'
      })

      seo = seoResponse?.data?.items
      faq = seoResponse?.data?.items?.[currentLocale]?.[0]?.QNA
    } catch (e) {}

    return { banners, seo, faq }
  },

  data() {
    return {
      types: {
        row: 'image'
      },
      isLoadingBanner: false,
      seo: null,
      faq: null,
      banners: null
    }
  },

  head() {
    const scripts = []
    if (this?.faq.length) {
      scripts.push({
        hid: 'ldjson-schema',
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: this.faq.map((item) => ({
            '@type': 'Question',
            name: item.question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: item.answer
            }
          }))
        }
      })
    }
    return {
      title: this.seo?.[this.$i18n.locale]?.[0]?.title,
      meta: [
        { hid: 'og:type', property: 'og:type', content: 'website' },
        {
          hid: 'description',
          name: 'description',
          content: this.seo?.[this.$i18n.locale]?.[0]?.description
        },
        {
          hid: 'og-title',
          property: 'og:title',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_title
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_description
        },
        {
          hid: 'og-url',
          property: 'og:url',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_url
        },

        {
          property: 'og:image',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_img
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `https://korzinka.uz/${
            this.$i18n.locale === 'ru' ? '' : this.$i18n.locale + ''
          }`
        },

        { rel: 'alternate', href: `https://korzinka.uz`, hreflang: 'ru' },
        { rel: 'alternate', href: `https://korzinka.uz/uz`, hreflang: 'uz' },
        { rel: 'alternate', href: `https://korzinka.uz/en`, hreflang: 'en' }
      ],
      script: [...scripts]
    }
  },

  mounted() {
    setTimeout(() => {
      this.isLoadingBanner = true
    }, 0)
  }

  // jsonld() {
  //   const data = this.seo[this.$i18n.locale || 'ru'][0].QNA.map((i, index) => ({
  //     '@type': 'Question',
  //     name: i.question,
  //     acceptedAnswer: {
  //       '@type': 'Answer',
  //       text: i.answer
  //     }
  //   }))

  //   return {
  //     // '@context': 'https://schema.org',
  //     // '@type': 'FAQPage',
  //     itemListElement: data
  //   }
  // }
}
