
export default {
  name: 'AppCookie',

  data: (vm) => ({
    cookie: false
  }),

  created() {
    const isCookie = this.$cookies.get('my-cookie', { parseJSON: false })
    if (isCookie !== 'yes') return

    this.cookie = true
  },

  methods: {
    cookieHandler() {
      this.$cookies.set('my-cookie', 'yes', {
        path: '/',
        maxAge: 60 * 60 * 24 * 14,
        secure: true,
        httpOnly: false,
        sameSite: 'lax'
      })

      this.cookie = true
    }
  }
}
