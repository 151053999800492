
  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        titleToShare: "Korzinka.uz",
        currentUrl: '',
        instagramProfile: "korzinkauz",
        youtubeChannel: "UCTlK86ffEvRSangeKlZ8pzQ",
        isSubscribe: false,
      }
    },
    mounted() {
      this.currentUrl = window.location.href
    }
  }
