
import arrowDanger from '~/static/lottiefiles/arrow-danger.json'
import arrowLight from '~/static/lottiefiles/arrow-white-without-animation.json'
export default {
  name: 'StandartBanner',

  props: {
    config: {
      type: Object,
      required: true,
      default: () => {}
    },
    lazyLoad: {
      type: Boolean,
      default: true, // Default value if not provided
    },
  },

  data: () => ({
    fileLottie: null
  }),

  mounted() {
    this.lottieFile(this.config.nav_color, this.config.id)
  },

  methods: {
    detectBrowserWidth() {
      if (process.client) {
        if (window.innerWidth > 500) {
          return true
        } else {
          return false
        }
      }
    },
    mq(desc, mob) {
      const removeFromFzPercent = Number(desc) * 0.4
      if (desc && !mob) {
        if (window.innerWidth >= 500) {
          return `${desc}px`
        } else {
          return `${desc - removeFromFzPercent}px`
        }
      } else {
        if (window.innerWidth >= 500) {
          return `${desc}px`
        } else {
          return `${mob}px`
        }
      }
    },
    navigate() {
      if (this.config.link.url !== '') {
        window.location.href = this.config.link.url
      }
    },
    // mq(fz) {
    //   if(process.client) {
    //     const removeFromFzPercent = Number(fz) * 0.4

    //     if (window.innerWidth >= 500) {
    //       return `${fz}px`
    //     } else {
    //       return `${fz - removeFromFzPercent}px`
    //     }
    //   }
    // },

    lottieFile(color, id) {
      this.fileLottie = window.lottie.loadAnimation({
        name: `id-${id}`,
        container: this.$el.querySelector(`.l${id}`),
        loop: false,
        autoplay: false,
        animationData: String(color) === 'red' ? arrowDanger : arrowLight
      })
    },

    startArrowLottieFile() {
      this.fileLottie.setDirection(1)
      this.fileLottie.setSpeed(3)
      this.fileLottie.play()
    },

    endArrowLottieFile() {
      this.fileLottie.setDirection(-1)
      this.fileLottie.setSpeed(3)
      this.fileLottie.play()
    }
  }
}
