
export default {
  name: 'SeoBlock',

  props: {
    seoData: {
      type: null,
      required: true,
      default: () => {
        return {}
      }
    }
  },

  data: () => ({
    seoBlockConfig: {
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      infinite: true,
      variableWidth: true
    },

    defDesHeight: 250,
    setDesHeight: 0,
    stateDes: false
  }),

  mounted() {
    const selfRef = this.$refs
    if (selfRef.des) {
      this.setDesHeight = selfRef.desIn.offsetHeight

      if (this.setDesHeight >= this.defDesHeight) {
        selfRef.des.style.height = `${this.defDesHeight}px`
      } else {
        selfRef.showButton.style.display = 'none'
      }
    } else {
      if( selfRef.showButton ) {
        selfRef.showButton.style.display = 'none'
      }
    }
  },

  methods: {
    handleSizeDes() {
      if (this.stateDes === false) {
        const selfRef = this.$refs
        this.setDesHeight = selfRef.desIn.offsetHeight + 10;

        selfRef.des.style.height = `${this.setDesHeight}px`
        selfRef.desIcon.classList.add('active')

        this.stateDes = true
      } else {
        const selfRef = this.$refs

        selfRef.des.style.height = `${this.defDesHeight}px`
        selfRef.desIcon.classList.remove('active')

        this.stateDes = false
      }
    }
  }
}
