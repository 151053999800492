
export default {
  name: 'DefaultLayout',

  data() {
    return {
      footerData: null
    }
  },

  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      }
    }
  },

  mounted() {
    window.scrollTo(0, 0);
    this.fetchFooterData();
  },
  methods: {
    async fetchFooterData() {
      try {
        const { data } = await this.$axios.$get(
          `${process.env.API_URL_SITE}/footer`
        )

        this.footerData = data;
      } catch(e) {}
    }
  }
}
