
export default {
  name: 'SpecialOfferPage',
  props: {
    config: {
      type: Object,
      required: false
    }
  },

  async asyncData({ app, env, $axios, route, params }) {
    const locale = app.i18n.locale
    const id = route.query.id
    let seo, faq, catalog, disclaimerHtml
    try {
      const response = await $axios.$get(`/mobile/catalog/${id}/products`)
      catalog = response.data
      let url
      if (locale === 'ru') {
        url = catalog.disclaimer.url_ru
      } else if (locale === 'en') {
        url = catalog.disclaimer.url_en
      } else if (locale === 'uz') {
        url = catalog.disclaimer.url_uz
      }
      const responseHtml = await $axios.$get(url)
      disclaimerHtml = responseHtml
        .replace(/<link[^>]*rel=["']stylesheet["'][^>]*>/gi, '')
        .replace(/<style[^>]*>[^<]*<\/style>/gi, '')
    } catch (e) {}
    try {
      const seoResponse = await $axios.$post(`${env.API_URL_SITE}/seo`, {
        url: `/catalog/special?id=${id}`,
        project: 'korzinka.uz'
      })

      seo = seoResponse?.data?.items
      faq = seoResponse?.data?.items?.[locale]?.[0]?.QNA
    } catch (e) {}

    return {
      catalog,
      seo,
      faq,
      disclaimerHtml
    }
  },

  data() {
    return {
      catalog: null,
      seo: null,
      faq: null,
      footer: {
        text: '* Стоимость товаров в каталоге указана в сумах* Предложения каталога распространяются только на сеть супермаркетов korzinka.* Цены и ассортимент товаров в каталоге могут не совпадать с ценами и ассортиментом товаров в магазинах korzinka DISKONT, korzinka Mahalla и korzinka Smart.* Цена лепешки действительна в супермаркетах, где имеется своя тандырная. * В регионах акция действует на куриные яйца от местных поставщиков.* Количество акционного товара ограничено.* Стоимость некоторых товаров может быть ниже, чем указано в каталоге',

        text_en: [
          ' The cost of goods in the catalog is indicated in soums',
          ' Catalog offers apply only to the korzinka supermarket chain.',

          ' The prices and assortment of products in the catalog may not match the prices and assortment of products in korzinka DISKONT, korzinka Mahalla and korzinka Smart stores.',

          ' The price of tandoor bread is actual in supermarkets, where there is a tandyr room.',

          ' In the regions, the promotion is valid for chicken eggs from local suppliers.',

          ' The quantity of promotional goods is limited.',
          ' The cost of some products may be lower than indicated in the catalog'
        ],

        text_uz: [
          ' Katalogdagi tovarlar narxi so‘mda ko‘rsatilgan.',

          ' Katalogdagi takliflar faqatgina Korzinka supermarketlar tarmog‘ida amal qiladi.',

          ' Tovarlar narxi va assortimenti Korzinka DISKONT, Korzinka Mahalla va Korzinka Smart do‘konlaridagi assortimentdan farq qilishi mumkin.',

          ' Tandir non narxi o‘z tandirxonasi bor supermarketlarda amal qiladi',

          ' Viloyat hududlaridagi supermarketlarimizda aksiya mahalliy yetkazuvchilar tovuq tuxumlari uchun ham o‘z kuchida.',

          ' Aksiya doirasidagi tovarlar soni cheklangan.',
          " Ba'zi tovarlar narxi katalogda ko‘rsatilganidan arzonroq bo‘lishi mumkin"
        ],

        text_ru: [
          ' Стоимость товаров в каталоге указана в сумах',

          ' Предложения каталога распространяются только на сеть супермаркетов korzinka.',

          ' Цены и ассортимент товаров в каталоге могут не совпадать с ценами и ассортиментом товаров в магазинах korzinka DISKONT, korzinka Mahalla и korzinka Smart.',

          ' Цена лепешки действительна в супермаркетах, где имеется своя тандырная. ',

          ' В регионах акция действует на куриные яйца от местных поставщиков.',
          ' Количество акционного товара ограничено.',

          ' Стоимость некоторых товаров может быть ниже, чем указано в каталоге'
        ]
      }
    }
  },

  head() {
    const baseUrl = 'https://korzinka.uz'
    const id = this.$route.query.id

    let url = ''
    const locale = this.$i18n.locale

    if (locale === 'ru') {
      url = `${baseUrl}/catalog/special?id=${id}`
    } else {
      url = `${baseUrl}/${locale}/catalog/special?id=${id}`
    }

    const scripts = []
    if (this?.faq.length) {
      scripts.push({
        hid: 'ldjson-schema',
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: this.faq.map((item) => ({
            '@type': 'Question',
            name: item.question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: item.answer
            }
          }))
        }
      })
    }

    return {
      title:
        this.seo?.[this.$i18n.locale]?.[0]?.title ||
        this.catalog?.[`title_${this.$i18n.locale}`] ||
        `Korzinka`,
      meta: [
        { hid: 'og:type', property: 'og:type', content: 'website' },
        {
          hid: 'description',
          name: 'description',
          content: this.seo?.[this.$i18n.locale]?.[0]?.description || ''
        },
        {
          hid: 'og-title',
          property: 'og:title',
          content:
            this.seo?.[this.$i18n.locale]?.[0]?.og_title ||
            this.catalog?.[`title_${this.$i18n.locale}`] ||
            `Korzinka`
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_description || ''
        },
        {
          hid: 'og-url',
          property: 'og:url',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_url || ''
        },
        {
          hid: 'og-image',
          property: 'og:image',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_img || ''
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image'
        }
      ],

      bodyAttrs: {
        class: `about_body ${this.catalog?.is_bar ? 'curr-banner' : ''}`
      },
      link: [
        {
          rel: 'canonical',
          href: url
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/special?id=${id}`,
          hreflang: 'ru'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/uz/special?id=${id}`,
          hreflang: 'uz'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/en/special?id=${id}`,
          hreflang: 'en'
        }
      ],
      script: [...scripts]
    }
  },

  mounted() {
    if (!this.$route.query.id || !this.catalog) {
      this.$router.push(`/catalog`)
    }
    const el = document.getElementById('disclemer')

    if (this.$route.hash === '#offer') {
      setTimeout(() => {
        window.scrollTo({ top: el.offsetTop, behavior: 'smooth' })
      })
    }
  },

  methods: {
    async fetchCatalog() {
      try {
        const response = await this.$axios.$get(
          `/mobile/catalog/${this.$route.query.id}/products`
        )

        this.catalog = response.data
      } catch (e) {}
    }
  }
}
