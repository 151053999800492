
import fetchMetaTags from '@/utils/FETCH_META_TAGS'
export default {
  name: 'CustomPage',

  async asyncData({ app, env, $axios, params }) {
    const currentLocale = app.i18n.locale
    const { slug } = params
    let page;
    let seoResponse;

    try {
      seoResponse = await $axios.$post(`${env.API_URL_SITE}/seo`, {
        url: `/page/${slug}`,
        project: 'korzinka.uz'
      })
    } catch (e) {}

    try {
      const pageResponse = await $axios.$get(
        `${process.env.API_URL_SITE}/page/`,
        {
          params: {
            url: `/${slug}/`
          }
        }
      )

      page = pageResponse.data.item

      // slug = page[currentLocale].url
    } catch (e) {}

    return {
      seo: seoResponse?.data?.items,
      faq: seoResponse?.data?.items?.[currentLocale]?.[0]?.QNA,
      page,
      slug: page[currentLocale].url
    }
  },
  data: () => ({
    page: [],
    seo: null,
    faq: null,
    slug: '',
    chances: 'шанс'
  }),

  head() {
    const baseUrl = 'https://korzinka.uz'
    const slug = this.$route.params.slug

    let url = ''
    const locale = this.$i18n.locale

    if (locale === 'ru') {
      url = `${baseUrl}/page/${slug}`
    } else {
      url = `${baseUrl}/${locale}/page/${slug}`
    }


    const scripts = [];
    if(this?.faq.length) {
      scripts.push({
        hid: 'ldjson-schema',
        type: 'application/ld+json',
        json: {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": this.faq.map(item => ({
            "@type": "Question",
            "name": item.question,
            "acceptedAnswer": {
              "@type": "Answer",
              "text": item.answer
            }
          }))
        }
      })
    }

    if(this.$route.path === '/page/virtualnaya-kassa-pos2k') {
      scripts.push({
        hid: 'jivo-site', // уникальный идентификатор для предотвращения дублирования тегов
        src: '//code.jivo.ru/widget/' + process.env.JIVO_ID, // URL скрипта
        type: 'text/javascript',
        async: true, // асинхронная загрузка скрипта
      },)
    }
    const metaTags = [
        {
          hid: 'description',
          name: 'description',
          content:
            this.seo?.[this.$i18n.locale]?.[0]?.description ||
            'Korzinka | Page description'
        },
        {
          hid: 'og-title',
          property: 'og:title',
          content:
            this.seo?.[this.$i18n.locale]?.[0]?.og_title ||
            'Korzinka | Page title'
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content:
            this.seo?.[this.$i18n.locale]?.[0]?.og_description ||
            'Korzinka | Page description'
        },
        {
          hid: 'og-url',
          property: 'og:url',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_url || ''
        },

        {
          hid: 'og-image',
          property: 'og:image',
          content: this.seo?.[this.$i18n.locale]?.[0]?.og_img || ''
        }
      ]

    if (!this.page?.[this.$i18n.locale]?.index) {
      metaTags.push({ hid: 'robots', name: 'robots', content: 'noindex, nofollow' })
    }

    return {
      title:
        this.seo?.[this.$i18n.locale]?.[0]?.title || 'Korzinka | Page title',

      meta: metaTags,
      link: [
      {
          rel: 'canonical',
          href: this.page?.[this.$i18n.locale]?.canonical || url,
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/page/${slug}`,
          hreflang: 'ru'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/uz/page/${slug}`,
          hreflang: 'uz'
        },
        {
          rel: 'alternate',
          href: `https://korzinka.uz/en/page/${slug}`,
          hreflang: 'en'
        }
      ],
      script: [
        ...scripts
      ]
    }
  },

  mounted() {
    // console.log(this.seo?.[this.$i18n.locale][0]);
    if (!this.$route.params.slug) {
      this.$router.push('/')
    } else {
      this.fetchPage()

      const cCh = document.querySelector('.coupon-checker')
      if (cCh) {
        this.handleCouponChecker()
      }
    }


  },

  beforeDestroy() {
    const jivoScript = document.querySelector('[data-hid="jivo-site"]');
    if (window.jivo_destroy && typeof window.jivo_destroy === 'function') {
      window.jivo_destroy();
    }
    if (jivoScript) {
      jivoScript.remove();
    }
  },

  methods: {
    async fetchPage() {},

    checkCountChances(count) {
      if (this.$i18n.locale === 'ru') {
        let formOfWord = 'шанс'

        if (count % 10 === 1 && count % 100 !== 11) {
          formOfWord += 'а'
        } else if (
          count % 10 >= 2 &&
          count % 10 <= 4 &&
          (count % 100 < 10 || count % 100 >= 20)
        ) {
          formOfWord += 'а'
        } else {
          formOfWord += 'ов'
        }

        this.chances = formOfWord
      } else if (this.$i18n.locale === 'uz') {
        this.chances = 'ta imkon bor'
      } else {
        this.chances = 'chances'
      }
    },

    handleCouponChecker() {
      const DATA = {
        title: {
          ru: 'Узнайте свои шансы на выигрыш',
          uz: 'Yutuq uchun imkonlaringizni aniqlang',
          en: 'Find out your chances of winning'
        },
        label: {
          ru: 'Введите номер карты лояльности',
          uz: 'Korzinka kartangiz raqamini kiriting',
          en: 'Enter your loyalty card number'
        },
        button: {
          ru: 'Проверить участие',
          uz: 'Ishtirokni tekshiring',
          en: 'Check Participation'
        },

        error: {
          ru: 'Похоже, вы пока не участвуете в акции или данные еще не обновлены. Если с момента покупки прошло больше 60 минут — свяжитесь с нами по телефону <a href="tel:+998781401414">+998 (78) 140-14-14</a>',
          uz: 'Siz hali aksiyada qatnashmayotgan koʻrinasiz yoki ma`lumotlaringiz yangilanmagan. Xarid qilganingizdan keyin 60 daqiqadan ko‘proq o‘tgan bo‘lsa — biz bilan <a href="tel:+998781401414">+998 (78) 140-14-14</a> raqami orqali bog‘laning',
          en: 'Looks like you`re not participating in the promotion yet or the data hasn`t been updated yet. If more than 60 minutes have passed since the purchase, please contact us by phone <a href="tel:+998781401414">+998 (78) 140-14-14</a>'
        },

        firstTitle: {
          ru: 'У вас',
          uz: 'Sizda',
          en: 'You have'
        },
        number: {
          ru: 'Номера участия',
          uz: 'Ishtirok raqamlari',
          en: 'Participation numbers'
        }
      }

      const checkerButton = document.querySelector('.coupon-checker__button')
      const checkerInput = document.querySelector(
        '.coupon-checker__form-group input'
      )
      const schedules = document.querySelector('.schedules')
      const scheduleItems = document.querySelector('.coupon-checker__schedules')
      const image = document.querySelector('.coupon-checker__image')
      const clearEl = document.querySelector('.coupon-checker__clear')
      const errorEl = document.querySelector('.coupon-checker__error')

      const odds = document.querySelector(
        '.coupon-checker__odds .coupon-checker__odds_l'
      )

      // Content
      const cTitle = document.querySelector('.coupon-checker__title')

      const cLabel = document.querySelector('.coupon-checker__form-group label')
      const cButton = document.querySelector('.coupon-checker__button span')
      const cTitleF = document.querySelector('.coupon-checker__odds_f')

      cTitle.textContent = DATA.title[this.$i18n.locale]

      cLabel.textContent = DATA.label[this.$i18n.locale]
      cButton.textContent = DATA.button[this.$i18n.locale]
      cTitleF.textContent = DATA.firstTitle[this.$i18n.locale]

      const couponTemplate = (coupon) => {
        const formatDate = String(coupon.date).split(' ')
        const modifiedDate = String(formatDate[0]).split('-')

        const date = `${modifiedDate[2]}.${modifiedDate[1]}.${modifiedDate[0]}`
        const time = formatDate[1]

        return `
          <div class="coupon-checker__schedule">
            <div class="coupon-checker__schedule-item">
              <div class="coupon-checker__coupons">
              ${Array.from(coupon.coupons)
                .map((i) => {
                  return `<span class="coupon-checker__coupon ${
                    i.has_expired ? 'expired' : ''
                  }">
                          <span>${i.id}</span>
                        </span>`
                })
                .join('')}
              </div>
              <span class="coupon-checker__number">${
                DATA.number[this.$i18n.locale]
              }</span>
            </div>
            <div class="coupon-checker__schedule-item">
              <span class="coupon-checker__date">${date}</span>
              <span class="coupon-checker__time">${time}</span>
            </div>
          </div>
        `
      }

      clearEl.addEventListener('click', (e) => {
        e.preventDefault()
        checkerInput.value = ''

        clearEl.classList.add('disable')
        checkerButton.classList.add('disable')

        image.classList.remove('disable')
        schedules.classList.remove('active')
        errorEl.textContent = ''
      })

      /* eslint-disable no-undef */
      const im = new Inputmask({
        mask: '99999 99999 99999 99999',
        placeholder: '',
        greedy: true
      })

      im.mask(checkerInput)

      checkerInput.addEventListener('input', (e) => {
        const _Length = String(e.target.value.replaceAll(/\s/g, '')).length

        if (_Length >= 12) {
          checkerButton.classList.remove('disable')
          errorEl.textContent = ''
        } else {
          checkerButton.classList.add('disable')
          errorEl.textContent = ''
        }

        if (_Length > 0) {
          clearEl.classList.remove('disable')
          errorEl.textContent = ''
        } else {
          clearEl.classList.add('disable')
          errorEl.textContent = ''
        }
      })

      checkerButton.addEventListener('click', async (e) => {
        e.preventDefault()

        try {
          const { data } = await this.$axios.get(
            `/promotions/coupons/${checkerInput.value.replaceAll(/\s/g, '')}`
          )
          errorEl.textContent = ''

          if (data.coupons_grouped_by_date.length) {
            this.checkCountChances(data.coupons_grouped_by_date.length)

            scheduleItems.innerHTML = ''

            image.classList.add('disable')

            schedules.classList.add('active')

            odds.textContent = `${data.coupons_amount} ${this.chances}`

            data.coupons_grouped_by_date.forEach((i) => {
              scheduleItems.insertAdjacentHTML('beforeend', couponTemplate(i))
            })
          } else {
            errorEl.innerHTML = DATA.error[this.$i18n.locale]
            image.classList.remove('disable')
            schedules.classList.remove('active')
          }
        } catch (e) {
          errorEl.textContent = e.response?.data.message
        }
      })
    }
  }
}
